import React from "react";
import FileLabelGroup from "./FileLabelGroup";
import { Label, Icon } from "semantic-ui-react";
import Remove from "../Remove";

export interface FileLabelProps {

  title: string;
  link?: string;
  removable?: boolean;
  remove?(): Promise<boolean>;
}

interface FileLabeState {
  open: boolean;
  loading: boolean;
}

export default class FileLabel extends React.Component<
  FileLabelProps,
  FileLabeState
> {
  static Group = FileLabelGroup;

  constructor(props: FileLabelProps) {
    super(props);
    this.state = {
      open: false,
      loading: false,
    };
  }

  render() {
    const { title, link, removable } = this.props;

    return (
      <div>
        <Label color="teal">
          <a href={link} target="_blank" style={{ opacity: 1 }}>
            {title}
          </a>
          {removable && (
            <Remove.Modal
              open={this.state.open}
              onClose={this.closeModal}
              trigger={<Icon name="close" onClick={this.openModal} />}
            >
              <Remove
                loading={this.state.loading}
                title={`Are you sure you want to remove ${title}?`}
                onRemove={this.onRemove}
                onCancel={this.closeModal}
              />
            </Remove.Modal>
          )}
        </Label>
      </div>
    );
  }

  openModal = () => this.setState({ open: true });
  closeModal = () => this.setState({ open: false });

  onRemove = () => {
    this.setState({ loading: true }, async () => {
      if (this.props.remove) {
        const deleted = await this.props.remove();
        this.setState({ loading: false, open: !deleted });
        return;
      }
      this.setState({ loading: false, open: false });
    });
  };
}
