import React from "react";
import CompleteCase from "../../../../models/CompleteCase";
import constants from "../../../../common/constants";
import ActionFold from "./actions/ActionFold";
import Page from "./Page";
import History from "./History";
import { Header, Modal, Button, Icon, Divider } from "semantic-ui-react";
import CaseProgressStepGroup from "./CaseProgressStepGroup";
import Case from "../../../../models/Case";
import CaseSummary from "./CaseSummary";
import ActionAllocation from "./actions/ActionAllocation";
import CaseAppendFormModal from "./CaseAppendFormModal";
import ActionQualityCheck from "./actions/ActionQualityCheck";
import Remarks from "./Remarks/Remarks";
import Reminders from "./Reminders";
import ActionClosure from "./actions/ActionClosure";
import ActionGrading from "./actions/ActionGrading";
import ActionFinalReport from "./actions/ActionFinalReport";
import ActionRemove from "./actions/ActionRemove";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../../redux";
import { Link } from 'react-router-dom'


interface CaseViewPageProps {
  title: string;
  description: string;
  model: CompleteCase;
  updateModel(c: CompleteCase): void;
}

const CaseViewPage = (props: CaseViewPageProps) => {
  const { model, updateModel } = props;
  const authState = useSelector((state: AppState) => state.auth);
  const loggedUserTitle = authState.user?.getRole().getTitle();

  let action = null;
  if (model.getState() === constants.caseStates.open || model.getState() === constants.caseStates.reinvestigate)
    action = (
      <ActionFold
        model={model}
        loggedUserTitle={loggedUserTitle}
        updateModel={updateModel}
      />
    );
  else if (model.getState() === constants.caseStates.fold)
    action = <ActionQualityCheck model={model} updateModel={updateModel} />;
  else if (model.getState() === constants.caseStates.checked)
    action = <ActionClosure model={model} updateModel={updateModel} />;
  else if (model.getState() === constants.caseStates.close)
    action = <ActionGrading model={model} updateModel={updateModel} />;
  else if (model.getState() === constants.caseStates.graded)
    action = <ActionFinalReport model={model} />;

  let actionRemoveComponent: JSX.Element | null = (
    <ActionRemove model={model} />
  );


  return (
    <Page historySidebar={<History model={model} />}>
      <div style={{ marginBottom: 300 }}>
        <Header as="h1">Case Detail</Header>
        <CaseProgressStepGroup model={model} />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <CaseAppendFormModal
            model={model}
            onSuccess={(c: Case) => updateModel(c as CompleteCase)}
          />
    
          <div>
            {/* remarks */}
            <Modal
              size="small"
              closeIcon
              closeOnDimmerClick={false}
              closeOnEscape={false}
              trigger={
                <Button
                  icon
                  labelPosition="left"
                  size="tiny"
                  primary={model.getRemarks().length == 0}
                  positive={model.getRemarks().length > 0}
                  disabled={model.getState() === constants.caseStates.initiated}
                >
                  <Icon name="pen square" />
                  Remarks
                </Button>
              }
            >
              <Modal.Header>Case Remarks</Modal.Header>
              <Modal.Content>
                <Remarks model={model} updateModel={updateModel}  />
              </Modal.Content>
            </Modal>
            {/* remarks */}

            {/* reminders */}
            <Modal
              size="small"
              closeIcon
              closeOnEscape={false}
              closeOnDimmerClick={false}
              trigger={
                <Button
                  icon
                  labelPosition="left"
                  size="tiny"
                  primary={model.getReminders().length == 0}
                  positive={model.getReminders().length > 0}
                  disabled={model.getState() === constants.caseStates.initiated}
                >
                  <Icon name="bell" />
                  Reminders
                </Button>
              }
            >
              <Modal.Header>Case Reminders</Modal.Header>
              <Modal.Content>
                <Reminders model={model} updateModel={updateModel} />
              </Modal.Content>
            </Modal>
            {/* reminders */}
          </div>
        </div>

        <Header as="h3">Case Summary</Header>
        <CaseSummary model={model} updateModel={updateModel} />

        <div style={{ marginTop: 24 }}>
          <ActionAllocation model={model} updateModel={updateModel} allocationId={model.getId()} claimNumber={model.getId()} />
        </div>

        {action}

        {actionRemoveComponent}
      </div>
    </Page>
  );
};

export default CaseViewPage;






























