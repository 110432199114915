import React from 'react'
import './index.css'

const Splash = () => {
    return (
        <div className='splash'>
            <div className='splash-logo'>Verifacto - Claim Probe</div>
        </div>
    )
}

export default Splash