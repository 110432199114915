import DataMapper from './DataMapper'
import AllocatedInvestigatorRM from '../responsemodel/AllocatedInvestigatorRM'
import AllocatedInvestigator, { RemovedEvidence } from '../../models/AllocatedInvestigator'
import UserDataMapper from './UserDataMapper'
import URLResource from '../../models/URLResource'
import LocationDataMapper from './LocationDataMapper'
import Location from '../../models/Location'

class AllocatedInvestigatorDataMapper extends DataMapper<AllocatedInvestigatorRM, AllocatedInvestigator> {
  private userDataMapper = new UserDataMapper()
  private locationDataMapper = new LocationDataMapper()

  map = (rm: AllocatedInvestigatorRM) => {
    const investigator = this.userDataMapper.map(rm.investigator)
    const allocatedBy = this.userDataMapper.map(rm.allocated_by)

    let investigationLocation: Location | null = null
    if (rm.investigation_location !== null) investigationLocation = this.locationDataMapper.map(rm.investigation_location)

    const evidence = rm.evidence.map((it) => new URLResource(it))
    const documentExpenseReceipt = rm.document_expense_receipt.map((it) => new URLResource(it))
    const approvedExpenseReceipt = rm.approved_expense_receipt.map((it) => new URLResource(it))
    const extraExpenseReceipt = rm.extra_expense_receipt.map((it) => new URLResource(it))
    // const removedEvidenceByInvestigator = rm.removed_evidence_by_investigator.map((investigator:any) => {
    //   return {
    //     type: investigator.type,
    //     document: investigator.document.map((url:any) => {
    //       return new URLResource(url);
    //     }),
    //   };
    // });

    const removedEvidence:RemovedEvidence[]=[]
    rm.removed_evidence_by_investigator.forEach((e:any)=>{
      removedEvidence.push({
        type: e.type, document: e.document.map((it: any) => new URLResource(it.toString())),
        getExtension: function (): unknown {
          throw new Error('Function not implemented.')
        },
        getURL: function (): RequestInfo {
          throw new Error('Function not implemented.')
        }
      })
    })
    // console.log(evidence)
    // console.log("__________________________")
    // console.log(removedEvidence)
    return new AllocatedInvestigator(
      rm.id,
      rm.id,
      rm.type_id,
      investigator,
      rm.investigation_specifications,
      allocatedBy,
      rm.allocated_on,
      rm.active,
      evidence,
      rm.outcome,
      rm.case_portion,
      investigationLocation,
      rm.allowance,
      rm.document_expense,
      documentExpenseReceipt,
      rm.approved_expense,
      approvedExpenseReceipt,
      rm.extra_expense,
      extraExpenseReceipt,
      // removedEvidenceByInvestigator
      removedEvidence
     
    )
  }
}

export default AllocatedInvestigatorDataMapper
