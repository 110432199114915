import React from "react";
import Page from "../../../layout/Page";
import {
  Modal,
  Icon,
  Button,
  DropdownItemProps,
  Segment,
  Header,
} from "semantic-ui-react";
import Result from "../../../../common/repository/Result";
import { toast } from "../../../common/Toast";
import Table from "../../../app/Table";
import RequirePermission from "../../../base/RequirePermission";
import permissions from "../../../../common/permissions";

import UploadBulkInvoiceForm from "./UploadBulkInvoiceForm";
import UploadBulkInvoiceReceiptForm from "./UploadBulkInvoiceReceiptForm";
import InvoiceTemplateDownloadForm from "./InvoiceTemplateDownloadForm";
import InvoiceUpdateForm from "./InvoiceUpdateForm";
import InvoiceSection from "../../../../models/InvoiceSection";
import InvoiceSectionColumnRenderer from "./InvoiceSectionColumnRenderer";
import Filters from "./Filters";
import FilterPageContext from "./FilterPageContext";
import ClientRepository from "../../../../common/repository/ClientRepository";
import InvoiceSectionRepository, {
  GetInvoiceSectionRequestObject,
} from "../../../../common/repository/InvoiceSectionRepository";
import CaseTypeRepository from "../../../../common/repository/CaseTypeRepository";

interface State {
  loading: boolean;

  filter: string;
  limit: number;
  offset: number;
  totalCount: number;
  invoiceSection: InvoiceSection[];

  update_modalOpen: boolean;
  update_model: InvoiceSection | null;

  remove_modalOpen: boolean;
  remove_model: InvoiceSection | null;

  downloadTemplateModalOpen: boolean;
  uploadTemplateModalOpen: boolean;
  invoiceReceiptModalOpen: boolean;

  loadingEssentialData: boolean;
  clientOptions: DropdownItemProps[];
  caseTypeOptions: DropdownItemProps[];
  clientFilter: string;
  caseTypeFilter: string;
  claimNumberFilter: string;
  invoiceNumberFilter: string;
  fromFilter: string;
  toFilter: string;
  fromInvoiceDateFilter: string;
  toInvoiceDateFilter: string;

  docPendingFilter: boolean;
  invoicePendingFilter: boolean;
  invoiceUploadPendingFilter: boolean;

  invoiceResponseSegmentStatus: boolean;
  invoiceResponseSegmentData: string[];

  bulkUploadSegmentVisible: boolean;
  bulkUploadSegmentData: number[];
  
  Reinvestigation: boolean
  Pushback: boolean
  generatedCase:boolean
}

class InvoiceSectionPage extends React.Component<{}, State> {
  private invoiceSectionRepo = new InvoiceSectionRepository();
  private clientRepo = new ClientRepository();
  private caseTypeRepo = new CaseTypeRepository();
  private filters: any;

  private columnRenderer = new InvoiceSectionColumnRenderer(
    (model: InvoiceSection) => {
      let receipt = <a className={"ui button"}>NA</a>;
      if (
        model.getInvoice() != null
          ? model.getInvoice()?.getInvoiceUrl()
            ? true
            : false
          : false
      ) {
        receipt = (
          <a
            className={"ui primary button"}
            href={`${model.getInvoice()?.getInvoiceUrl()}`}
            target={"_blank"}
          >
            View
          </a>
        );
      }
      return (
        <>
          {receipt}
          <Button
            positive
            onClick={() =>
              this.setState({ update_modalOpen: true, update_model: model })
            }
          >
            Open
          </Button>
        </>
      );
    },
    (obj: any) => true
  );


  constructor(props: any) {
    super(props);

    this.filters = props.history.location.state;
    this.state = {
      loading: false,
      limit: Table.ROWS_PER_PAGE[Table.ROWS_PER_PAGE.length - 1] * 2,
      offset: 0,
      filter: "",
      totalCount: 0,
      invoiceSection: [],
      update_modalOpen: false,
      update_model: null,
      downloadTemplateModalOpen: false,
      uploadTemplateModalOpen: false,
      invoiceReceiptModalOpen: false,
      remove_modalOpen: false,
      remove_model: null,

      loadingEssentialData: true,
      clientOptions: [],
      caseTypeOptions: [],
      clientFilter: "",
      caseTypeFilter: "",
      claimNumberFilter: "",
      invoiceNumberFilter: "",
      fromFilter: "",
      toFilter: "",
      fromInvoiceDateFilter: "",
      toInvoiceDateFilter: "",

      docPendingFilter: false,
      invoicePendingFilter: false,
      invoiceUploadPendingFilter: false,

      invoiceResponseSegmentStatus: false,
      invoiceResponseSegmentData: [],

      bulkUploadSegmentData: [],
      bulkUploadSegmentVisible: false,

      Reinvestigation: false,
      Pushback: false,
      generatedCase:false,
    };
  }

  async componentDidMount() {
    this.loadData();
    await this.effectLoadEssentialData();
  }

  private setFilter = (filter: string) => {
    this.setState({ filter, offset: 0 }, this.loadData);
  };

  private clearAllFilters = async () => {
    await this.setState({
      limit: 0,
      offset: 0,
      caseTypeFilter: "",
      clientFilter: "",
      claimNumberFilter: "",
      invoiceNumberFilter: "",
      docPendingFilter: false,
      invoicePendingFilter: false,
      invoiceUploadPendingFilter: false,
      fromFilter: "",
      toFilter: "",
      fromInvoiceDateFilter: "",
      toInvoiceDateFilter: "",
      Reinvestigation: false,
      Pushback: false,
      generatedCase:false,
    });
    await this.loadData();
  };

  private onInvoiceSectionUpdated = async (prop: InvoiceSection) => {
    const invoiceSection = this.state.invoiceSection;
    const index = invoiceSection.findIndex((it) => it.getId() === prop.getId());
    if (index !== -1) invoiceSection[index] = prop;

    this.setState({
      invoiceSection: [...invoiceSection],
      update_modalOpen: false,
      update_model: null,
    });
  };

  private getInvoiceSection = async () => {
    const ro: GetInvoiceSectionRequestObject = {
      limit: this.state.limit,
      offset: this.state.offset,
    };
    if (this.state.generatedCase) {
      ro.case_state = "generated"; 
  }
  
    if (this.state.filter) ro.search = this.state.filter;
  
    if (this.state.claimNumberFilter) 
      ro.claim_number = this.state.claimNumberFilter;
    
    if (this.state.invoiceNumberFilter) 
      ro.invoice_number = this.state.invoiceNumberFilter;
    
    if (this.state.clientFilter) 
      ro.client_id = this.state.clientFilter;
    
    if (this.state.caseTypeFilter) 
      ro.case_type_id = this.state.caseTypeFilter;
    
    if (this.state.fromFilter) 
      ro.from = this.state.fromFilter;
    
    if (this.state.toFilter) 
      ro.to = this.state.toFilter;
    
    // if (this.state.generatedCase) 
    //   ro.generatedCase = this.state.generatedCase;
  
    if (this.state.fromInvoiceDateFilter) 
      ro.from_invoice_date = this.state.fromInvoiceDateFilter;
    
    if (this.state.toInvoiceDateFilter) 
      ro.to_invoice_date = this.state.toInvoiceDateFilter;
  
    ro.docReceived = this.state.docPendingFilter;
    ro.invoicePending = this.state.invoicePendingFilter;
    ro.invoiceUploadPending = this.state.invoiceUploadPendingFilter;
  
    if (this.state.Reinvestigation) 
      ro.reinvestigation = this.state.Reinvestigation;
    
    if (this.state.Pushback) 
      ro.pushback = this.state.Pushback;
  
    return await this.invoiceSectionRepo.getData(ro);
  };
  

  private bulkUploadResponse = (rs: any) => {
    if ((rs as number[]).length > 0)
      this.setState({
        uploadTemplateModalOpen: false,
        bulkUploadSegmentVisible: true,
        bulkUploadSegmentData: rs,
      });
    else this.setState({ uploadTemplateModalOpen: false });
    this.loadData();
  };

  private bulkInvoiceReceiptUploadResponse = (rs: any) => {
    this.setState({
      invoiceResponseSegmentData: rs,
      invoiceResponseSegmentStatus: true,
      invoiceReceiptModalOpen: false,
    });
    this.loadData();
  };

  /**
   * loads new data
   * if offset is 0 then set it as new list
   * else appends to the old list
   */
  private loadData = async () => {
    let offset = this.state.offset;

    this.setState({ loading: true }, async () => {
      const result = await this.getInvoiceSection();

      if (result instanceof Result.Success) {
        if (offset === 0) {
          //if offset 0 initialize as new list
          const invoice = result.data.items;
          const totalCount = result.data.totalItemCount;
          this.setState({
            loading: false,
            offset: invoice.length,
            invoiceSection: invoice,
            totalCount: totalCount,
          });
        } else {
          //else check for duplicate items in the new data
          // and then concat with the old
          const invoiceSection = [
            ...this.state.invoiceSection,
            ...result.data.items,
          ];
          const offset = invoiceSection.length;
          this.setState({ loading: false, offset, invoiceSection });
        }
      } else {
        const message = result.message || "Something went wrong";
        this.setState({ loading: false }, () => toast.error(message));
      }
    });
  };

  private fetchData = async () => {
    await this.setState({ limit: 100, offset: 0 });
    if (
      this.state.caseTypeFilter ||
     
      this.state.invoiceUploadPendingFilter ||
      this.state.docPendingFilter ||
      this.state.invoicePendingFilter ||
      this.state.fromFilter ||
      this.state.toFilter ||
      this.state.clientFilter ||
      this.state.claimNumberFilter ||
      this.state.invoiceNumberFilter ||
      this.state.filter ||
      this.state.fromInvoiceDateFilter ||
      this.state.toInvoiceDateFilter ||
      this.state.Pushback ||
      this.state.generatedCase ||
      this.state.Reinvestigation
   
    ) {
      await this.setState({ offset: 0 });
    }
    this.loadData();
  };

  private effectLoadEssentialData = async () => {
    if (!this.state.loadingEssentialData) return;
    // const p0 = await this.roleRepo.getRoles();
    // if(p0 instanceof Result.Success){
    //     p0.data.items.forEach(it=>{
    //         if(it.getTitle() == constants.roleTitle.investigator){
    //             userRole = it.getId();
    //         }
    //     })
    // }
    const p1 = this.clientRepo.getClients();

    const p2 = this.caseTypeRepo.getCaseTypes({});
    const results = await Promise.all([p1, p2]);

    if (results[0] instanceof Result.Success) {
      this.setState({
        clientOptions: results[0].data.items.map((it) => ({
          text: it.getName(),
          value: it.getId(),
          key: it.getId(),
        })),
      });
    } else {
      const message = results[0].message || "Could not load Clients";
      toast.error(message);
    }

    if (results[1] instanceof Result.Success) {
      this.setState({
        caseTypeOptions: results[1].data.items.map((it) => ({
          text: it.getTitle(),
          value: it.getId(),
          key: it.getId(),
        })),
      });
    } else {
      const message = results[1].message || "Could not load Case Types";
      toast.error(message);
    }

    this.setState({ loadingEssentialData: false });
  };

  private setDocPendingFilter = (val: boolean) =>
    this.setState({ docPendingFilter: val });
  private setInvoicePendingFilter = (val: boolean) =>
    this.setState({ invoicePendingFilter: val });
  private setInvoiceUploadPendingFilter = (val: boolean) =>
    this.setState({ invoiceUploadPendingFilter: val });
  private setClientFilter = (val: string) =>
    this.setState({ clientFilter: val });
  private setCaseTypeFilter = (val: string) =>
    this.setState({ caseTypeFilter: val });
  private setClaimNumberFilter = (val: string) =>
    this.setState({ claimNumberFilter: val });
  private setInvoiceNumberFilter = (val: string) =>
    this.setState({ invoiceNumberFilter: val });
  private setFromFilter = (val: string) => this.setState({ fromFilter: val });
  private setToFilter = (val: string) => this.setState({ toFilter: val });
  private setFromInvoiceDateFilter = (val: string) =>
    this.setState({ fromInvoiceDateFilter: val });
  private setToInvoiceDateFilter = (val: string) =>
    this.setState({ toInvoiceDateFilter: val });
  private setReinvestigation = (val: boolean) => this.setState({ Reinvestigation: val })
  private setPushback = (val: boolean) => this.setState({ Pushback: val })
  private setGeneratedCase=(val:boolean)=>this.setState({generatedCase:val})

  render() {
    let invoiceReceiptUploadResponse = null;
    if (this.state.invoiceResponseSegmentStatus) {
      invoiceReceiptUploadResponse = (
        <Segment>
          <h4>
            Files with these names are skipped &nbsp;&nbsp;
            <Button
              onClick={() =>
                this.setState({
                  invoiceResponseSegmentStatus: false,
                  invoiceResponseSegmentData: [],
                })
              }
            >
              Dismiss
            </Button>{" "}
          </h4>
          <p>
            {this.state.invoiceResponseSegmentData.map((it) => {
              return <span style={{ padding: "10px" }}>{it} &nbsp; </span>;
            })}
          </p>
        </Segment>
      );
    }

    let bulkUploadResponse = null;
    if (this.state.bulkUploadSegmentVisible) {
      bulkUploadResponse = (
        <Segment>
          <h4>
            Rows Skipped while bulk Uploading &nbsp;&nbsp;
            <Button
              onClick={() =>
                this.setState({
                  bulkUploadSegmentVisible: false,
                  bulkUploadSegmentData: [],
                })
              }
            >
              Dismiss
            </Button>{" "}
          </h4>
          <p>
            {this.state.bulkUploadSegmentData.map((it) => {
              return <span style={{ padding: "10px" }}>{it} &nbsp; </span>;
            })}
          </p>
        </Segment>
      );
    }

    return (
      <FilterPageContext.Provider
        value={{
          options: {
            client: this.state.clientOptions,
            caseType: this.state.caseTypeOptions,
          },
          filters: {
            client: this.state.clientFilter,
            setClient: this.setClientFilter,
            caseType: this.state.caseTypeFilter,
            setCaseType: this.setCaseTypeFilter,
            claimNumber: this.state.claimNumberFilter,
            setClaimNumber: this.setClaimNumberFilter,
            invoiceNumber: this.state.invoiceNumberFilter,
            setInvoiceNumber: this.setInvoiceNumberFilter,
            from: this.state.fromFilter,
            setFrom: this.setFromFilter,
            to: this.state.toFilter,
            setTo: this.setToFilter,
            fromInvoiceDate: this.state.fromInvoiceDateFilter,
            setFromInvoiceDate: this.setFromInvoiceDateFilter,
            toInvoiceDate: this.state.toInvoiceDateFilter,
            setToInvoiceDate: this.setToInvoiceDateFilter,
            applyFilters: this.fetchData,
            clearAllFilters: this.clearAllFilters,
            searchByDocPending: this.state.docPendingFilter,
            searchByInvoicePending: this.state.invoicePendingFilter,
            searchByUploadPending: this.state.invoiceUploadPendingFilter,
            setSearchByDocPending: this.setDocPendingFilter,
            setSearchByInvoicePending: this.setInvoicePendingFilter,
            setSearchByUploadPending: this.setInvoiceUploadPendingFilter,
            Reinvestigation: this.state.Reinvestigation,
            setReinvestigation: this.setReinvestigation,
            Pushback: this.state.Pushback,
            setPushback: this.setPushback,
            generatedCase:this.state.generatedCase,
            setGeneratedCase:this.setGeneratedCase,
          },
        }}
      >
        <Page title="Invoice Record" description="Manage invoice related data">
          {bulkUploadResponse}

          {invoiceReceiptUploadResponse}

          <Segment.Group>
            <Segment>
              <Modal
                open={this.state.downloadTemplateModalOpen}
                size="mini"
                closeIcon
                closeOnDimmerClick={false}
                closeOnEscape={false}
                onClose={() =>
                  this.setState({ downloadTemplateModalOpen: false })
                }
                trigger={
                  <span>
                    <label style={{ marginRight: 16 }}>Step 1:</label>
                    <Button
                      primary
                      onClick={() =>
                        this.setState({ downloadTemplateModalOpen: true })
                      }
                    >
                      Download Template
                    </Button>
                  </span>
                }
              >
                <Modal.Header>Download Template</Modal.Header>
                <Modal.Content>
                  <InvoiceTemplateDownloadForm
                    onSuccess={() => {
                      this.setState({ downloadTemplateModalOpen: false });
                    }}
                  />
                </Modal.Content>
              </Modal>

              <Icon name="arrow right" style={{ margin: "0 2rem" }} />
              <label>Step 2: Fill it.</label>
              <Icon name="arrow right" style={{ margin: "0 2rem" }} />
              <Modal
                open={this.state.uploadTemplateModalOpen}
                size="mini"
                closeIcon
                closeOnDimmerClick={false}
                closeOnEscape={false}
                onClose={() =>
                  this.setState({ uploadTemplateModalOpen: false })
                }
                trigger={
                  <span>
                    <label style={{ marginRight: 16 }}>Step 3:</label>
                    <Button
                      onClick={() => {
                        this.setState({ uploadTemplateModalOpen: true });
                      }}
                      primary
                    >
                      Upload Invoice Template
                    </Button>
                  </span>
                }
              >
                <Modal.Header>Upload Invoice Template</Modal.Header>
                <Modal.Content>
                  <UploadBulkInvoiceForm onSuccess={this.bulkUploadResponse} />
                </Modal.Content>
              </Modal>

              <Icon name="arrow right" style={{ margin: "0 2rem" }} />
              <Modal
                open={this.state.invoiceReceiptModalOpen}
                size="mini"
                closeIcon
                closeOnDimmerClick={false}
                closeOnEscape={false}
                onClose={() =>
                  this.setState({ invoiceReceiptModalOpen: false })
                }
                trigger={
                  <span>
                    <label style={{ marginRight: 16 }}>Step 4:</label>
                    <Button
                      onClick={() => {
                        this.setState({ invoiceReceiptModalOpen: true });
                      }}
                      primary
                    >
                      Upload Invoices Copies
                    </Button>
                  </span>
                }
              >
                <Modal.Header>Upload Invoice Receipts</Modal.Header>
                <Modal.Content>
                  <UploadBulkInvoiceReceiptForm
                    onSuccess={this.bulkInvoiceReceiptUploadResponse}
                  />
                </Modal.Content>
              </Modal>
            </Segment>
          </Segment.Group>
          <Segment>
            <Filters />
          </Segment>
          {/*Update invoice section Modal */}
          <Modal
            open={this.state.update_modalOpen}
            onClose={() =>
              this.setState({ update_modalOpen: false, update_model: null })
            }
            size="tiny"
            closeIcon
            closeOnDimmerClick={false}
            closeOnEscape={false}
          >
            <Modal.Header>Update Information</Modal.Header>
            <Modal.Content>
              <InvoiceUpdateForm
                model={this.state.update_model || undefined}
                onSuccess={this.onInvoiceSectionUpdated}
              />
            </Modal.Content>
          </Modal>
          {/*Update invoice section Modal */}

          <Table
            renderer={this.columnRenderer}
            data={this.state.invoiceSection}
            totalDataCount={this.state.totalCount}
            loading={this.state.loading}
            load={this.loadData}
            onSearch={this.setFilter}
            onClear={() => this.setFilter("")}
          />
        </Page>
      </FilterPageContext.Provider>
    );
  }
}

export default (props: any) => {
  return (
    <RequirePermission permission={permissions.Billing.update}>
      <InvoiceSectionPage {...props} />
    </RequirePermission>
  );
};
