import React, { useContext } from "react";
import { Grid, Form, Header, Segment, Radio } from "semantic-ui-react";
import FilterPageContext from "./FilterPageContext";
import { toast } from "../../../common/Toast";
import DateTimePicker from "../../../app/DateTimePicker";

function Filters() {
  const ctx = useContext(FilterPageContext);

  let data: { key: string; value: any }[] = [];
  if (ctx.filters.to) data.push({ key: "to", value: ctx.filters.to });
  if (ctx.filters.from) data.push({ key: "from", value: ctx.filters.from });
  if (ctx.filters.toInvoiceDate)
    data.push({ key: "toInvoiceDate", value: ctx.filters.toInvoiceDate });
  if (ctx.filters.fromInvoiceDate)
    data.push({ key: "fromInvoiceDate", value: ctx.filters.fromInvoiceDate });
  if (ctx.filters.claimNumber)
    data.push({ key: "claimNumber", value: ctx.filters.claimNumber });
  if (ctx.filters.client)
    data.push({ key: "client", value: ctx.filters.client });
  if (ctx.filters.caseType)
    data.push({ key: "caseType", value: ctx.filters.caseType });
  // if(ctx.filters.generatedCase)
  //   data.push({ value:ctx.filters.generatedCase});

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Header as="h4">Filter</Header>
      </div>
      <Grid columns={4}>
        <Grid.Row>
          <Grid.Column>
            <Segment>
              <Radio
                toggle
                label={"Document Received"}
                checked={ctx.filters.searchByDocPending}
                onChange={() => {
                  // if(ctx.filters.searchByUploadPending || ctx.filters.searchByInvoicePending)
                  //     toast.error("Only One Filter Applied at a Time!!");
                  // else
                  ctx.filters.setSearchByDocPending(
                    !ctx.filters.searchByDocPending
                  );
                  ctx.filters.setSearchByUploadPending(false);
                }}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <Radio
                toggle
                label={"Invoice Pending"}
                checked={ctx.filters.searchByInvoicePending}
                onChange={() => {
                  // if(ctx.filters.searchByUploadPending || ctx.filters.searchByDocPending)
                  //    toast.error("Only One Filter Applied at a Time!!");
                  // else
                  ctx.filters.setSearchByInvoicePending(
                    !ctx.filters.searchByInvoicePending
                  );
                  ctx.filters.setSearchByUploadPending(false);
                }}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <Radio
                toggle
                label={"Invoice Upload Pending"}
                checked={ctx.filters.searchByUploadPending}
                onChange={() => {
                  // if(ctx.filters.searchByDocPending || ctx.filters.searchByInvoicePending)
                  //     toast.error("Only One Filter Applied at a Time!!");
                  // else
                  ctx.filters.setSearchByUploadPending(
                    !ctx.filters.searchByUploadPending
                  );
                  ctx.filters.setSearchByInvoicePending(false);
                  ctx.filters.setSearchByDocPending(false);
                }}
              />
            </Segment>
          </Grid.Column>

          <Grid.Column>
            <Segment>
              <Radio
                toggle
                label={"Generated Case"}
                checked={ctx.filters.generatedCase}
            
                onChange={() => {
                  ctx.filters.setGeneratedCase(!ctx.filters.generatedCase);
                
                  ctx.filters.setSearchByInvoicePending(false);
                  ctx.filters.setSearchByDocPending(false);
                  ctx.filters.setSearchByUploadPending(false);
          
                }}
              />

            </Segment>
          </Grid.Column>

          <Grid.Column>
            <Form.Input
              label="Invoice Number"
              placeholder="Enter Invoice Number"
              value={ctx.filters.invoiceNumber}
              onChange={(_, { value }) => ctx.filters.setInvoiceNumber(value)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Form.Input label="From Case Close Date">
              <DateTimePicker
                placeholder="Select From Date"
                type="date"
                value={ctx.filters.from}
                onChange={ctx.filters.setFrom}
              />
            </Form.Input>
          </Grid.Column>
          <Grid.Column>
            <Form.Input label="To Case Close Date">
              <DateTimePicker
                placeholder="Select To Date"
                type="date"
                value={ctx.filters.to}
                onChange={ctx.filters.setTo}
              />
            </Form.Input>
          </Grid.Column>

          <Grid.Column>
            <Form.Input label="From Invoice Date">
              <DateTimePicker
                placeholder="Select From Date"
                type="date"
                value={ctx.filters.fromInvoiceDate}
                onChange={ctx.filters.setFromInvoiceDate}
              />
            </Form.Input>
          </Grid.Column>
          <Grid.Column>
            <Form.Input label="To Invoice Date">
              <DateTimePicker
                placeholder="Select To Date"
                type="date"
                value={ctx.filters.toInvoiceDate}
                onChange={ctx.filters.setToInvoiceDate}
              />
            </Form.Input>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Form.Dropdown
              label="Case Type"
              search
              selection
              placeholder="Choose Case Type"
              options={ctx.options.caseType}
              value={ctx.filters.caseType}
              onChange={(_, { value }) =>
                ctx.filters.setCaseType(value as string)
              }
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Dropdown
              label="Client(TPA/Company)"
              search
              selection
              placeholder="Select Client"
              options={ctx.options.client}
              value={ctx.filters.client}
              onChange={(_, { value }) =>
                ctx.filters.setClient(value as string)
              }
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Input
              label="Claim Number"
              placeholder="Enter Claim Number"
              value={ctx.filters.claimNumber}
              onChange={(_, { value }) => ctx.filters.setClaimNumber(value)}
            // onKeyDown={(e:any)=>{if(e.keyCode==13)ctx.filters.setClaimNumber(claimNumber)}}
            />
          </Grid.Column>

          <Grid.Column >
            <Segment compact>
              <Radio
                toggle
                label={"Reinvestigation"}
                checked={ctx.filters.Reinvestigation}
                onChange={() => {
                  ctx.filters.setReinvestigation(
                    !ctx.filters.Reinvestigation
                  );
                }}
              />
            </Segment>
          </Grid.Column>

          <Grid.Column>
            <Segment compact>
              <Radio
                toggle
                label={"Pushback"}
                checked={ctx.filters.Pushback}
                onChange={() => {
                  ctx.filters.setPushback(
                    !ctx.filters.Pushback
                  );
                }}
              />
            </Segment>
          </Grid.Column>

          <Grid.Column>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp;
            <div className="ui buttons">
              <button
                className="ui blue button"
                onClick={ctx.filters.applyFilters}
              >
                Apply Filters
              </button>
              {/*<div className="or"></div>*/}
              <button
                className="ui button"
                onClick={ctx.filters.clearAllFilters}
              >
                Clear
              </button>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

export default Filters;
