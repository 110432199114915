import React from "react";
import BaseProps from "../../base/BaseProps";
import styled from "styled-components";

const BaseGroup = styled.div`
  display: flex;
`;

const VerticalGroup = styled(BaseGroup as any)`
  flex-direction: column;
  * {
    margin-bottom: 2px;
  }
`;

const HorizontalGroup = styled(BaseGroup as any)`
  flex-direction: row;
  flex-wrap: wrap;
  * {
    margin-right: 2px;
    margin-bottom: 2px;
  }
`;

interface Props extends BaseProps {
  horizontal?: boolean;
  style?: React.CSSProperties; // Add this line to support the style prop
}

const FileLabelGroup = (props: Props) => {
  const { horizontal, children, style } = props;
  if (horizontal) {
    return <HorizontalGroup style={style}>{children}</HorizontalGroup>;
  } else {
    return <VerticalGroup style={style}>{children}</VerticalGroup>;
  }
};

export default FileLabelGroup;
