import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

interface CallPageProps {
  contactNumber: any;
  onEndCall: () => void;
}

const CallPage: React.FC<CallPageProps> = ({ contactNumber, onEndCall }) => {
  return (
    <div className="call-page">
      <div className="call-header">
        <Icon name="phone" size="big" />
        <h2>Calling {contactNumber}...</h2>
      </div>
      <div className="call-body">
        <p>Connecting...</p>
        {/* You can add a timer, call status, or other features here */}
      </div>
      <div className="call-footer">
        <Button negative onClick={onEndCall}>
          <Icon name="stop circle" />
          End Call
        </Button>
      </div>

      {/* Add some CSS for styling */}
      <style>{`
        .call-page {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          height: 300px; /* Adjust height for small size */
          /* Adjust width for small size */
          padding: 10px;
          background-color: #f8f9fa;
        }
        .call-header {
          text-align: center;
          margin-bottom: 10px;
        }
        .call-body {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px; /* Adjust font size for small size */
        }
        .call-footer {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 10px;
        }
      `}</style>
    </div>
  );
};

export default CallPage;
