import React, { useState, useEffect } from "react";
import { Segment, Form, Header, Icon, Divider } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { setAuth } from "../../../redux/auth/actioncreators";
import { useHistory, useLocation } from "react-router-dom";
import LoginRepository from "../../../common/repository/LoginRepository";
import Result from "../../../common/repository/Result";
import { toast } from "../../common/Toast";
import LocalStorage from "../../../lib/LocalStorage";

const LoginForm = () => {
  const loginRepo = new LoginRepository();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  /** hook to perform login operation */
  useEffect(() => {
    if (!loading) return;

    (async () => {
      const result = await loginRepo.login({ email, password });
      if (result instanceof Result.Success) {/* api */
        const { token, user } = result.data;
        LocalStorage.set(LocalStorage.KEY_AUTH_TOKEN, token);
        dispatch(setAuth(user));
        let redirectTo = location.state || "/";
        history.push(redirectTo);
      } else {
        const message = result.message || "Something went wrong";
        toast.error(message);
      }
      setLoading(false);
    })();
  }, [loading]);

  return (
    <Segment.Group>
      <Segment>
        <Header as="h1" ><span> Verifactio  - Web Dashboard</span></Header>
      </Segment>

      <Segment>
        <Form widths="equal" loading={loading}>
          <Form.Input
            label="Email"
            placeholder="someone@something.com"
            value={email}
            onChange={(_, { value }) => setEmail(value)}
          />
          <Form.Input
            label="Password"
            type="password"
            placeholder="ho1dMytea"
            value={password}
            onChange={(_, { value }) => setPassword(value)}
          />

          <Divider hidden />

          <Form.Button
            size="large"
            primary
            icon
            labelPosition="right"
            disabled={loading || email.length === 0 || password.length === 0}
            onClick={() => setLoading(true)}
          >
            <Icon name="arrow right" />
            Login
          </Form.Button>
          <b>
            <a onClick={() => history.push("/forgot-password")}>
              Forgot Password
            </a>
          </b>
        </Form>
      </Segment>

      <Segment textAlign="center">
        {/* <strong>Dashboard</strong> powered by <strong>WebHopers</strong> */}
      </Segment>
    </Segment.Group>
  );
};

export default LoginForm;
