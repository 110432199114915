// import React, { useState, useEffect, useRef } from "react";
// import { Form, Button, Icon } from "semantic-ui-react";

// interface Props {
//   title: string;
//   actionText: string;
//   disable(): void;  // Assuming disable is an async function
//   cancel(): void;
// }

// const DisableConfirmationForm: React.FC<Props> = ({ title, disable, cancel, actionText }) => {
//   const [loading, setLoading] = useState(false);
//   const isMountedRef = useRef(true);  // Using useRef to track component mounted status

//   useEffect(() => {
//     isMountedRef.current = true;  // Set to true when the component mounts

//     return () => {
//       isMountedRef.current = false;  // Cleanup: Set to false when the component unmounts
//     };
//   }, []);  // Empty array ensures the effect runs only once

//   const handleDisable = async () => {
//     setLoading(true);
//     await disable();
//     if (!isMountedRef.current) return;  // Prevent state update if unmounted
//     setLoading(false);
//   };
  

//   return (
//     <Form loading={loading}>
//       <p>{title}</p>
//       <Button color = {actionText === "Disable" ? "red" : "green"} inverted onClick = {handleDisable}>
//         {actionText}
//       </Button>
//       <Button onClick={cancel} style={{ marginLeft: 4 }}>
//         Cancel
//       </Button>
//     </Form>
//   );
// };

// export default DisableConfirmationForm;
import React, { useState, useEffect, useRef } from "react";
import { Form, Button } from "semantic-ui-react";

interface Props {
  title: string;
  actionText: string;
  disable(): void;  // Assuming disable is an async function
  cancel(): void;
}

const DisableConfirmationForm: React.FC<Props> = ({ title, disable, cancel, actionText }) => {
  const [loading, setLoading] = useState(false);
  // const isMountedRef = useRef(true);  // Using useRef to track component mounted status

  // useEffect(() => {
  //   isMountedRef.current = true;  // Set to true when the component mounts

  //   return () => {
  //     isMountedRef.current = false;  // Cleanup: Set to false when the component unmounts
  //   };
  // }, []);  // Empty array ensures the effect runs only once

  const handleDisable = async () => {
    setLoading(true);
    try {
      await disable(); // Await the disable action
    } catch (error) {
      console.error("Failed to disable:", error); // Log error
    }
      // } finally {
    //   if (isMountedRef.current) {
    //     setLoading(false); // Ensure loading state is reset
    //   }
    // }
  };


  // Handle form submission
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent the default form submission
    handleDisable(); // Call the disable function
  };

  return (
    <Form loading={loading} onSubmit={handleSubmit}>
      <p>{title}</p>
      <Button color={actionText === "Disable" ? "red" : "green"} inverted type="submit" disabled={loading}>
        {actionText}
      </Button>
      <Button onClick={cancel} style={{ marginLeft: 4 }}>
        Cancel
      </Button>
    </Form>
  );
};

export default DisableConfirmationForm;
