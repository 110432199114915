import React, { useState, useEffect } from "react";
import { Form } from "semantic-ui-react";
import Result from "../../../../common/repository/Result";
import { toast } from "../../../common/Toast";
import DocumentPendencyRepository from "../../../../common/repository/DocumentPendencyRepository";

function UploadDocumentPendencyForm({ onSuccess }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const documentPendencyRepo = new DocumentPendencyRepository();

  useEffect(() => {
    uploadSheet();
  }, [isLoading, file]);

  return (
    <Form loading={isLoading}>
      <Form.Input
        type="file"
        label="Document Pendency Sheet"
        accept=".xlsx"
        onChange={(e) => {
          if (e.target.files) setFile(e.target.files[0]);
          else setFile(null);
        }}
      />
      <Form.Button
        positive
        disabled={isLoading || file === null}
        onClick={() => setIsLoading(true)}
      >
        Submit
      </Form.Button>
    </Form>
  );

  async function uploadSheet() {
    if (!isLoading || file === null) return;
    const result = await documentPendencyRepo.uploadDocumentPendencySheet(file);
    if (result instanceof Result.Success) {
      toast.success("Sheet uploaded successfully");
      if (onSuccess) onSuccess();
    } else {
      const message = result.message || "Something went wrong";
      toast.error(message);
    }
    setIsLoading(false);

    if (onSuccess) onSuccess();
  }
}

interface Props {
  onSuccess?(): void;
}

export default UploadDocumentPendencyForm;
