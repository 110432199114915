import React, { useEffect, useState } from 'react';
import './MrdNotification.css';
import LocalStorage from '../../../lib/LocalStorage';
import config from '../../../common/repository/config';
import { toast } from '../../common/Toast';
import { Button, Modal, Dropdown, DropdownItemProps, Form, Message } from 'semantic-ui-react';

interface Metadata {
  investigator_name: string;
  hospital_name: string | null;
  claim_number: string | null;
  insurer_name: string | null;
  charges: string | null;
  investigator_id: string | undefined;
  case_id: string | undefined;
}


interface Notification {
  id: string;
  type_id: string; 
  history_id: string | null;
  metadata: Metadata;
  message: string | null;
  title: string | null;
  type: string | null;
}

interface ApiResponse {
  success: boolean;
  message?: string;
  data?: {
    items?: Notification[];
  };
}

const MrdNotification: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error' | null>(null);

  const [selectedDetail, setSelectedDetail] = useState<Notification | null>(null);
  const [rejectReason, setRejectReason] = useState<string>('');
  const [rejectionReasons, setRejectionReasons] = useState<DropdownItemProps[]>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [approving, setApproving] = useState<boolean>(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  // useEffect(() => {
  //   const fetchNotifications = async () => {
  //     try {
  //       const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
  //       if (!token) {
  //         setToastMessage('Authentication token is missing.');
  //         setToastType('error');
  //         return;
  //       }
  //       const response = await fetch(`${config.apiBaseURL}/notification/missedMrd`, {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'x-access-token': token,
  //         },
  //       });

  //       if (!response.ok) throw new Error('Failed to fetch missed MRD notifications');

  //       const data: ApiResponse = await response.json();
  //       console.log(data, "{{{[")
  //       if (data.success && data.data?.items) {
  //         setNotifications(data.data.items);
  //       } else {
  //         setToastMessage('No notifications found.');
  //       }
  //     } catch (error) {
  //       setToastMessage('Error fetching missed MRD notifications.');
  //       setToastType('error');
  //       console.error('Error fetching missed MRD notifications:', error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   const fetchRejectionReasons = async () => {
  //     try {
  //       const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
  //       if (!token) {
  //         console.error('Authentication token is missing.');
  //         return;
  //       }

  //       const response = await fetch(`${config.apiBaseURL}/case/listAmountRejectionReason`, {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'x-access-token': token,
  //         },
  //       });

  //       if (!response.ok) throw new Error('Failed to fetch rejection reasons');

  //       const data = await response.json();
  //       if (data.success && data.data?.items) {
  //         const reasons: DropdownItemProps[] = Object.entries(data.data.items).map(([key, value]) => ({
  //           key,
  //           text: value as string,
  //           value: key,
  //         }));
  //         setRejectionReasons(reasons);
  //       } else {
  //         setToastMessage('No rejection reasons found.');
  //       }
  //     } catch (error) {
  //       console.error('Error fetching rejection reasons:', error);
  //     }
  //   };

  //   fetchNotifications();
  //   fetchRejectionReasons();
  // }, []);

  const handleReject = (notification: Notification) => {
    setSelectedDetail(notification);
    setRejectReason('');
    setError(null);
    setModalVisible(true);
  };

  const handleRejectConfirm = async () => {
    if (!selectedDetail) return;
  
    try {
      const { case_id, investigator_id } = selectedDetail.metadata;
      const { type_id, history_id } = selectedDetail;
  
      // Ensure all required fields are present
      if (!case_id || !investigator_id || !type_id || !rejectReason) {
        setError('Missing required fields.');
        return;
      }
  
      const status = 'Rejected';
  
      await approveMRDReceipt(
        case_id,
        investigator_id,
        type_id, 
        selectedDetail.metadata.charges!, // Ensure you're sending the right amount
        status,
        rejectReason, // This should be the rejection reason selected
        history_id
      );
  
      // toast.success("MRD receipt rejected successfully");
      setModalVisible(false);
      setSelectedDetail(null);
      setRejectReason("");
    } catch (error) {
      console.error('Error rejecting MRD receipt:', error);
      toast.error("Failed to reject MRD Amount");
    }
  };
  

  const approveMRDReceipt = async (
    caseId: string,
    investigatorId: string,
    typeId: string,
    amount: string,
    status: string,
    rejectReason: string,
    historyId: string | null
  ) => {
    try {
      const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
      if (!token) throw new Error('Authentication token is missing.');
  
      const payload = {
        caseId,
        investigatorId,
        amount, 
        historyId,
        receiptStatus: status,
        amountStatus: status,
        rejectionReason: rejectReason || null, 
        typeId, 
      };
  
      const response = await fetch(`${config.apiBaseURL}/case/mrdAmountStatus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
        body: JSON.stringify(payload), // Send the payload as the body
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to approve MRD receipt: ${response.status} ${response.statusText} ${errorText}`);
      }
  
      const data = await response.json();
      toast.success(`Amount ${status === 'Approved' ? 'approved' : 'rejected'} successfully`);
    } catch (error) {
      console.error(`Error approving MRD receipt: `, error);
      throw error;
    }
  };
  

  useEffect(() => {
    if (toastMessage && toastType) {
      if (toastType === 'success') {
        toast.success(toastMessage);
      } else if (toastType === 'error') {
        toast.error(toastMessage);
      }

      setToastMessage(null);
      setToastType(null);
    }
  }, [toastMessage, toastType]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="mrd-notification">
      <h2> MRD  Ammount Notification</h2>
      <table className="notification-table">
        <thead>
          <tr>
            <th>Title</th>
            <th>Hospital Name</th>
            <th>Claim Number</th>
            <th>MRD Charges</th>
            <th>Investigator Name</th>
            <th>Insurer Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {notifications.length === 0 ? (
            <tr>
              <td colSpan={6} style={{ textAlign: 'center' }}>No missed MRD notifications.</td>
            </tr>
          ) : (
            notifications.map(notification => (
              <tr key={notification.id}>
                <td>{notification.title}</td>
                <td>{notification.metadata.hospital_name || 'Not Found'}</td>
                <td>{notification.metadata.claim_number || 'Not Found'}</td>
                <td>{notification.metadata.charges || 'Not Found'}</td>
                <td>{notification.metadata.investigator_name || 'Not Found'}</td>
                <td>{notification.metadata.insurer_name || 'Not Found'}</td>
                <td>
                  <Button
                    primary
                    style={{ backgroundColor: 'green', color: 'white', marginRight: '10px' }}
                    onClick={() => {
                      const status = 'Approved';
                      if (isApproved) {
                        toast.error("This request has already been approved.");
                        return;
                      }

                      if (isProcessing) return; // Prevent multiple clicks
                      setIsProcessing(true);

                      approveMRDReceipt(
                        notification.metadata.case_id!,
                        notification.metadata.investigator_id!,
                        notification.type_id,
                        notification.metadata.charges!,
                        status,
                        '', 
                        notification.history_id
                      );
                      setIsApproved(true);
                      setIsProcessing(false);

                    }}
                  // disabled={isApproved || isProcessing}
                  >
                    {isProcessing ? (
                      <span>Loading...</span> // You can replace this with a spinner icon if preferred
                    ) : (
                      'Approve'
                    )}
                  </Button>
                  <Button
                    style={{ backgroundColor: 'red', color: 'white' }}
                    onClick={() => handleReject(notification)}
                    
                  >
                    Reject
                  </Button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      {modalVisible && selectedDetail && (
        <Modal open={modalVisible} onClose={() => setModalVisible(false)}>
          <Modal.Header>Reason for Rejection</Modal.Header>
          <Modal.Content>
            <div>
              <div style={{ marginBottom: '15px' }}>
                <strong>Investigator Name:</strong> {selectedDetail.metadata.investigator_name}
              </div>
              <Form>
                <Form.Field>
                  <label>Select Rejection Reason</label>
                  <Dropdown
                    placeholder='Select Reason'
                    fluid
                    selection
                    options={rejectionReasons}
                    value={rejectReason||""}
                    onChange={(e, { value }) => setRejectReason(value as string)}
                  />
                </Form.Field>
                {error && <Message negative>{error}</Message>}
              </Form>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={() => setModalVisible(false)}
            >
              Cancel
            </Button>
            <Button
              positive
              onClick={handleRejectConfirm}
            >
              Confirm Rejection
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </div>
  );
};

export default MrdNotification;
  
