import DomainModel from './DomainModel'
import URLResource from './URLResource'
import ClaimType from './ClaimType'
import Branch from './Branch'
import User from './User'

class Client extends DomainModel {
  constructor(
    private id: string,
    private name: string,
    private type: string,
    private disbale: boolean,
    private handler: User,
    private MOUExpirationDate: string,
    private MOUDocument: URLResource | null,
    private claimTypes: ClaimType[],
    private branches: Branch[]
  ) {
    super()
  }

  getId = () => this.id
  getName = () => this.name
  getType = () => this.type
  getdisable = () => this.disbale
  getHandler = () => this.handler
  getMOUExpirationDate = () => this.MOUExpirationDate
  getMOUDocument = () => this.MOUDocument
  getClaimTypes = () => this.claimTypes
  getBranches = () => this.branches
}

export default Client
