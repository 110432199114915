import CaseCategory from '../models/CaseCategory'

const baseUrl = 'http://192.168.0.144:3111'
// const baseUrl = 'http://74.208.25.43:3111'
export default {
  baseUrl,
  defaultProfileAvatarURL: `https://amable.s3.ap-south-1.amazonaws.com/uploads/user/user.png`,

  //also change value in NavigationLayout/index.css
  sidebarWidth: 90,

  caseCategories: [new CaseCategory('1', 'Low TAT Investigation', 'LTI'), new CaseCategory('2', 'Detailed Investigation', 'DTI'), new CaseCategory('3', 'High Value Investigation', 'HVI')],

  caseStates: {
    initiated: 'initiated',
    generated: 'generated',
    open: 'open',
    fold: 'fold',
    checked: 'checked',
    close: 'close',
    graded: 'graded',
    reinvestigate: 'reinvestigate',

  },

  caseOutcome: {
    repudiation: 'Repudiation',
    genuine: 'Genuine',
    inconclusive: 'Inconclusive',
  },

  roleTitle: {
    admin: 'Admin',
    ceo: 'C.E.O.',
    md: 'M.D.',
    oh: 'Operations Head',
    tl: 'Team Lead',
    ca: 'Claim Analyst',
    investigator: 'Investigator',
    aa: 'Admin Assistant',
    ao: 'Admin Officer',
  },

  UDI: {
    CaseCategory: {
      code: {
        LTI: 'LTI',
        DTI: 'DTI',
        HVI: 'HVI',
      },
    },

    CaseType: {
      title: {
        Cashless: 'Cashless',
      },
    },

    Role: {
      title: {
        Investigator: 'Investigator',
        Claim_Analyst: 'Claim Analyst',
      },
    },
  },

  paymentStatus: {
    pending: 'pending',
    partial: 'partial',
    complete: 'complete',
  },
}
