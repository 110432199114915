import React, { useEffect, useState } from 'react';
import './invoicePopup.css';
import { useHistory, useLocation } from 'react-router-dom';

interface NotificationPopupProps {
  notificationData: {
    title: string;
    type: string;
    type_id: string;
    history_id?: string;
    message: string;
    metadata: {
      document_expense: number;
      document_expense_receipt: Array<any>;
      approved_expense: number;
      approved_expense_receipt: Array<any>;
      extra_expense: number;
      claim_number: number;
      case_id: number;
    };
  };
}
const NotificationPopup: React.FC<NotificationPopupProps> = ({ notificationData }) => {
  const [showPopup, setShowPopup] = useState(true); 
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (notificationData) {
      setShowPopup(true);
    }
  }, [notificationData]);

  const isOnViewPage = location.pathname === '/InvoiceNotificationList';

  const handleView = () => {
    setShowPopup(false); 
    history.push({
      pathname: '/InvoiceNotificationList',
      state: { notificationData },
    });
  };

  useEffect(() => {
    if (!isOnViewPage) {
      setShowPopup(true); 
    }
  }, [location]);

  // if (!showPopup || isOnViewPage) return null;

  return (
    <div className={`notification-popup ${showPopup ? 'show' : ''}`}>
      <div className="overlay"></div>
      <div className="dialog-container">
        <div className="dialog-content">
          <div className="notification-details">
            <h1>{notificationData.title}</h1>
            <h2>Claim Number: {notificationData.metadata.claim_number}</h2>
            <h3>{notificationData.message}</h3>
          </div>
          
          <div className="button-container">
            <button className="dialog-button primary" onClick={handleView}>
              View
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationPopup;
