import React, { useState, useEffect } from 'react'
import { Form, Header, Input, Divider, Button, Modal } from 'semantic-ui-react'
import validator from '../../../common/validator'
import FormLabel from '../../common/FormLabel'
import DateTimePicker from '../../app/DateTimePicker'
import ChangePasswordForm from './ChangePasswordForm'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from '../../../redux'
import UserRepository from '../../../common/repository/UserRepository'
import Result from '../../../common/repository/Result'
import { toast } from '../../common/Toast'
import { setAuth } from '../../../redux/auth/actioncreators'

const UserForm = () => {
    const authState = useSelector((state: AppState) => state.auth)
    const user = authState.user!
    const [name, setName] = useState(user.getName())
    const [phone, setPhone] = useState(user.getPhone())
    const [otherPhone, setOtherPhone] = useState(user.getOtherPhone() || '')
    const [email, setEmail] = useState(user.getEmail())
    const [otherEmail, setOtherEmail] = useState(user.getOtherEmail() || '')
    const [dob, setDob] = useState(user.getDOB() || '')
    const [address, setAddress] = useState(user.getAddress() || '')
    const [profilePicFile, setProfilePicFile] = useState<File | null>(null)
    const [submitting, setSubmitting] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => { effectSubmitForm() }, [submitting])

    return (
        <Form loading={submitting}>
            <Header as='h4' color='grey'>General Information</Header>

            {/* employee id input */}
            <Form.Group inline>
                <Form.Field required>
                    <label style={{ width: 100 }}>Employee ID</label>
                    <Input
                        disabled
                        placeholder='101'
                        value={user.getEmployeeId()} />
                </Form.Field>
            </Form.Group>
            {/* employee id input */}

            {/* name input */}
            <Form.Group inline>
                <Form.Field required>
                    <label style={{ width: 100 }}>Name</label>
                    <Input 
                        placeholder='Admin'
                        value={name}
                        onChange={(_, { value }) => setName(value)} />
                </Form.Field>
            </Form.Group>
            {/* name input */}


            {/* phone input */}
            <Form.Group inline>
                <Form.Field required>
                    <label style={{ width: 100 }}>Phone</label>
                    <Input 
                        placeholder='Primary'
                        value={phone}
                        onChange={(_, { value }) => {
                            if (validator.phone.isValid(value))
                                setPhone(value)
                        }} />
                </Form.Field>
                <Input 
                    placeholder='Secondary (optional)'
                    value={otherPhone}
                    onChange={(_, { value }) => {
                        if (validator.phone.isValid(value))
                            setOtherPhone(value)
                    }} />
            </Form.Group>
            {/* phone input */}


            {/* email input */}
            <Form.Group inline>
                <Form.Field required>
                    <label style={{ width: 100 }}>Email</label>
                    <Input 
                        placeholder='Primary'
                        value={email}
                        onChange={(_, { value }) => setEmail(value)} />
                </Form.Field>
                <Input 
                    placeholder='Secondary (optional)'
                    value={otherEmail}
                    onChange={(_, { value }) => setOtherEmail(value)} />
            </Form.Group>
            {/* email input */}


            {/* joining date */}
            <Form.Group inline>
                <Form.Field required>
                    <FormLabel width='100px'>Joining Date</FormLabel>
                    <Input disabled value={user.getJoiningDate()}/>
                </Form.Field>
            </Form.Group>
            {/* joining date */}
        
            <Divider />

            <Header as='h4' color='grey'>Personal Information</Header>

            {/* date of birth */}
            <Form.Group inline>
                <Form.Field>
                    <FormLabel width='100px'>Date of Birth</FormLabel>
                    <Input>
                    <DateTimePicker
                        type='date'
                        placeholder='Pick DOB'
                        value={dob}
                        onChange={value => setDob(value)} />
                    </Input>
                </Form.Field>
            </Form.Group>
            {/* date of birth */}


            {/* home address */}
            <Form.Group inline>
                <Form.Field>
                    <label style={{ width: 100 }}>Home Address</label>
                    <Input 
                        placeholder='Street Address/Landmark'
                        value={address}
                        onChange={(_, { value }) => setAddress(value)}/>
                </Form.Field>
            </Form.Group>
            {/* home address */}


            {/* profile pic url */}
            <Form.Group inline>
                <Form.Field>
                    <label style={{ width: 100 }}>Profile Picture</label>
                    <Input 
                        type='file'
                        accept='image/*'
                        onChange={(e) => {
                            if (e.target.files && e.target.files[0]) {
                                const file = e.target.files[0]
                                if (file instanceof File) {
                                    setProfilePicFile(file)
                                } else {
                                    setProfilePicFile(null)
                                }
                            }
                        }}/>
                </Form.Field>
            </Form.Group>
            {/* profile pic url */}

            <Modal
                open={isModalOpen}
                size='tiny'
                closeIcon
                closeOnDimmerClick={false}
                closeOnEscape={false}
                onClose={closeModal}
                trigger={<p onClick={openModal} style={{ color: 'blue', cursor: 'pointer', fontSize: 15 }}>Change Password?</p>}>
                <Modal.Header>Change Password</Modal.Header>
                <Modal.Content>
                    <ChangePasswordForm onSuccess={closeModal}/>
                </Modal.Content>
            </Modal>


            <Button 
                primary
                loading={submitting}
                disabled={shouldDisableSubmit()}
                size='large'
                style={{ margin: '16px 0' }}
                onClick={() => setSubmitting(true)}>
                Submit
            </Button>
        </Form>
    )

    function shouldDisableSubmit() {
        return (
            submitting ||
            name.trim().length === 0 ||
            phone.trim().length === 0 ||
            email.trim().length === 0
        )
    }

    async function effectSubmitForm() {
        if (!submitting)
            return
        const repo = new UserRepository()
        const result = await repo.updateUser({
            id: user.getId(),
            name: name,
            phone: phone,
            other_phone: otherPhone,
            email: email,
            other_email: otherEmail,
            dob: dob,
            address: address,
            profile_pic: profilePicFile || undefined
        })

        if (result instanceof Result.Success) {
            const user = result.data
            toast.success('Profile updated successfully')
            dispatch(setAuth(user))
        } else {
            const message = result.message || 'Something went wrong'
            toast.error(message)
        }
        setSubmitting(false)
    }

    function openModal() {
        setIsModalOpen(true)
    }

    function closeModal() {
        setIsModalOpen(false)
    }
}

export default UserForm