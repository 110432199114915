import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Result from "../../../../../common/repository/Result";
import RequirePermission from "../../../../base/RequirePermission";
import permissions from "../../../../../common/permissions";
import { Segment, Table, Header, Button } from "semantic-ui-react";
import CaseInvestigatorHistory from "../../../../../models/CaseInvestigatorHistory";
import CaseRepository from "../../../../../common/repository/CaseRepository";
import moment from "moment";
import config from '../../../../../common/repository/config'
import axios from 'axios'
import { toast } from '../../../../common/Toast'
import LocalStorage from '../../../../../lib/LocalStorage'


interface MatchProps {
    Id: string;
}
interface Props extends RouteComponentProps<MatchProps> { }
interface State {
    loading: boolean;
    model: CaseInvestigatorHistory | null;
    investigatorDetail: CaseInvestigatorHistory[]
    downloadedData: string

}
class CaseIvestigatorsHistory extends React.Component<Props, State> {
    private CaseRepository = new CaseRepository()
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            model: null,
            investigatorDetail: [],
            downloadedData: "",
        };
        console.log(this.props.match.params, "this.props.match.params.caseId;")
    }

    componentDidMount() {
        this.getCaseDataHistory()
    }

    private getCaseDataHistory = () => {
        const caseId = this.props.match.params;
        this.setState({ loading: true }, async () => {
            const result = await this.CaseRepository.getCaseDataHistory(caseId.Id);
            if (result instanceof Result.Success) {
                const investigatorDetails = result.data.items.map(it => it);
                console.log(investigatorDetails, "hhh")
                this.setState({ loading: false, investigatorDetail: investigatorDetails });
            } else {
                this.setState({ loading: false }, () => {
                    const message = result.message || "Could not AttendentInfo Data";
                    //   toast.error(message);
                });
            }
        });
    };


    private downloadTemplate = async () => {
        const caseId = this.props.match.params;
        const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN)
        let reqData: any
        try {
            toast.success('Download will start shortly!')
            axios({
                url: config.apiBaseURL + `/case/caseDetailData/download`,
                method: 'post',
                responseType: 'blob',
                data: { id: caseId.Id },
                headers: { ['x-access-token']: token, Accept: 'application/zip' },
                onDownloadProgress: (progressEvent:any) => {
                    // let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    // console.log(progressEvent.lengthComputable)

                    // let percentage = `${Math.round(progressEvent.loaded / 1024 / 1024)} MB Downloaded`
                    // this.setState({ downloadedData: percentage })
                    // console.log(downloadedData)
                },
            })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', 'Invesigatort History.xlsx')
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                })
                .catch((e) => {
                    console.log(JSON.stringify(e.response))
                    toast.error('Something went wrong while downloading data!')
                })
        } catch (e) {
            toast.error('Something went wrong while downloading')
        }
    }

    render() {
        return (
            <Segment>
                <Segment>
                    <Header as="h1">
                        Case Investigator History
                    </Header>
                    <Button primary onClick={this.downloadTemplate}>
                        {this.state.downloadedData != '' ? this.state.downloadedData : 'Download Template'}
                    </Button>
                </Segment>
                {this.state.investigatorDetail.map(it => (
                    <Table striped compact celled structured>
                        <Table.Body style={{ fontWeight: 700 }}>
                            <Table.Row>
                                <Table.Cell width={3}>State</Table.Cell>
                                <Table.Cell width={3}>
                                    {<h3>{it?.getCaseInvestigatorHistoryState()}</h3>}
                                </Table.Cell>
                                <Table.Cell width={3}>Date</Table.Cell>
                                <Table.Cell>
                                    {it.getCaseInvestigatorHistoryDate() ? moment(it.getCaseInvestigatorHistoryDate()).format("LLL") : "--"}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>

                                {/* <Table.Cell width={3}>Date</Table.Cell>
                                <Table.Cell>
                                    {it.getCaseInvestigatorHistoryDate() ? moment(it.getCaseInvestigatorHistoryDate()).format("LLL") : "--"}
                                </Table.Cell> */}

                                <Table.Cell width={3}>Name</Table.Cell>
                                <Table.Cell width={3}>
                                    {it?.getCaseInvestigatorHistoryName()}
                                </Table.Cell>
                                <Table.Cell width={3}>Remark</Table.Cell>
                                <Table.Cell width={3}>
                                    {it?.getCaseInvestigatorHistoryRemark() ? it?.getCaseInvestigatorHistoryRemark() : "--"}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={3}>Start Date</Table.Cell>
                                <Table.Cell>
                                    {it?.getCaseInvestigatorHistoryStartDate() ? moment(it?.getCaseInvestigatorHistoryStartDate()).format("LLL") : "--"}
                                </Table.Cell>

                                <Table.Cell width={3}>End Date</Table.Cell>
                                <Table.Cell width={3}>
                                    {it?.getCaseInvestigatorHistoryEndDate() ? moment(it?.getCaseInvestigatorHistoryEndDate()).format("LLL") : "--"}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Document</Table.Cell>
                                <Table.Cell colSpan={3}>  {it.getCaseInvestigatorHistoryDocument() ? <a className={"ui green  button"}
                                    href={`${config.mrdRecipt}${it.getCaseInvestigatorHistoryDocument()}`}
                                    target={"_blank"}
                                >
                                    Doc
                                </a> : "--"}
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                ))}
            </Segment>
        );

    }
}

export default (props: any) => {
    return (
        <RequirePermission permission={permissions.Case.allocate}>
            <CaseIvestigatorsHistory {...props} />
        </RequirePermission>
    );
};
