import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../../App";
import Cards from "./Cards";
import { Segment, DropdownItemProps, Button, Icon, Grid } from "semantic-ui-react";
import Page from "../../layout/Page";
import Filters from "./Filters";
import ClientRepository from "../../../common/repository/ClientRepository";
import RoleRepository from "../../../common/repository/RoleRespository";
import StateDistrictRepository from "../../../common/repository/StateDistrictRepository";
import UserRepository from "../../../common/repository/UserRepository";
import CaseRepository, {
  GetCaseCountRequestObject,
} from "../../../common/repository/CaseRepository";
import CaseCategoryRepository from "../../../common/repository/CaseCategoryRepository";
import Result from "../../../common/repository/Result";
import { toast } from "../../common/Toast";
import HomePageContext from "./HomePageContext";
import constants from "../../../common/constants";
import { useHistory } from "react-router-dom";
import CaseTypeRepository from "../../../common/repository/CaseTypeRepository";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../redux";
import HospitalRepository from "../../../common/repository/HospitalRepository";
import CaseColumnRenderer from "../case/CaseListPage/CaseColumnRenderer";
import LocalStorage from "../../../lib/LocalStorage";
import axios from "axios";
import config from "../../../common/repository/config";
import StatesDistrictsRepository from "../../../common/repository/StatesDistrictsRepository";
import NotifecationRepo from "../../../common/repository/NotifecationRepo";
import NotificationPopup from "../../layout/Header/NotificationPopUp";

function HomePage() {

  const authState = useSelector((state: AppState) => state.auth);
  const dispatch = useDispatch();
  const user = authState.user;
  const history = useHistory();
  const clientRepo = new ClientRepository();
  const roleRepo = new RoleRepository();
  const caseCategoryRepo = new CaseCategoryRepository();
  const stateRepo = new StateDistrictRepository();
  const userRepo = new UserRepository();
  const caseRepo = new CaseRepository();
  const hospitalRepo = new HospitalRepository();
  const caseTypeRepo = new CaseTypeRepository();
  const caseColumnRenderer = new CaseColumnRenderer();
  const notifecationRepo = new NotifecationRepo()
  const statesDistRepo = new StatesDistrictsRepository();

  const [loadingEssentialData, setLoadingEssentialData] = useState(true);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingCaseCount, setLoadingCaseCount] = useState(true);
  const [stateInitiatedCount, setStateInitiatedCount] = useState("-");
  const [stateGeneratedCount, setStateGeneratedCount] = useState("-");
  const [stateOpenCount, setStateOpenCount] = useState("-");
  const [stateFoldCount, setStateFoldCount] = useState("-");
  const [stateCheckedCount, setStateCheckedCount] = useState("-");
  const [stateCloseCount, setStateCloseCount] = useState("-");
  const [stateGradedCount, setStateGradedCount] = useState("-");
  const [stateReinvestigateCount, setStateReinvestigateCount] = useState("-");
  const [source, _] = useState(caseRepo.createSource());

  const [roleOptions, setRoleOptions] = useState<DropdownItemProps[]>([]);
  const [userOptions, setUserOptions] = useState<DropdownItemProps[]>([]);
  const [caseCategoryOptions, setCaseCategoryOptions] = useState<
    DropdownItemProps[]
  >([]);
  const [caseTypeOptions, setCaseTypeOptions] = useState<DropdownItemProps[]>(
    []
  );
  const [clientOptions, setClientOptions] = useState<DropdownItemProps[]>([]);

  const [stateOptions, setStateOptions] = useState<DropdownItemProps[]>([]);
  const [districtOptions, setDistrictOptions] = useState<DropdownItemProps[]>([]);
  const [AllDistrictS, setAllDistricts] = useState<DropdownItemProps[]>([])


  const [hospitalOptions, setHospitalOptions] = useState<DropdownItemProps[]>([]);
  const [searchFilter, setSearchFilter] = useState("");
  const [userRoleFilter, setUserRoleFilter] = useState("");
  const [userFilter, setUserFilter] = useState("");
  const [caseCategoryFilter, setCaseCategoryFilter] = useState("");
  const [caseStateFilter, setCaseStateFilter] = useState("");
  const [clientFilter, setClientFilter] = useState("");

  const [statesFilter, setStatesFilter] = useState("");
  const [districtFilter, setDistrictFilter] = useState("");

  const [claimNumberFilter, setClaimNumberFilter] = useState("");
  const [doctorNameFilter, setDoctorNameFilter] = useState("");
  const [hospitalFilter, setHospitalFilter] = useState("");
  const [hospitalSearch, setHospitalSearch] = useState("")
  const [caseTypeFilter, setCaseTypeFilter] = useState("");
  const [insuredNameFilter, setInsuredNameFilter] = useState("");
  const [fromFilter, setFromFilter] = useState("");
  const [toFilter, setToFilter] = useState("");
  const [getByClosedDate, setGetByClosedDate] = useState(false);
  const [getReinvestigation, setGetReinvestigation] = useState(false);
  const [getPushback, setPushback] = useState(false);
  // const [Counts, SetCounts] = useState<{ [key: string]: any }>({});
  const [showPopup, setShowPopup] = useState(false);

  const { Count, setCounts } = useContext<any>(GlobalContext)

  useEffect(effectTriggerLoadCaseCount, [
    userFilter,
    caseCategoryFilter,
    caseTypeFilter,
    caseStateFilter,
    clientFilter,
    statesFilter,
    districtFilter,
    claimNumberFilter,
    hospitalFilter,
    insuredNameFilter,
    doctorNameFilter,
    fromFilter,
    toFilter,
    getByClosedDate,
    getReinvestigation,
    getPushback,
    searchFilter,
  ]);
  useEffect(() => {
    effectLoadCaseCount();
  }, [loadingCaseCount]);
  useEffect(() => {
    effectLoadEssentialData();

  }, [loadingEssentialData]);
  useEffect(() => {
    effectLoadUsers();
  }, [userRoleFilter]);

  useEffect(() => {
    effectLoadDistrict()
  }, [statesFilter])
  useEffect(() => {
    GetCount()
  }, [])
  const refreshCount = async () => {
    localStorage.removeItem("caseCounts");
    localStorage.removeItem("caseFilters");
    setLoadingCaseCount(true);
    await effectLoadCaseCount();
  };
  const downloadData = async () => {
    let rs = createCountRequestObject();
    if (!rs) rs = {};
    const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
    try {
      if (Object.entries(rs).length > 0) {
        toast.success("Download will start shortly!");
        let reqData = axios({
          url: config.apiBaseURL + `/case/export-excel`,
          method: "POST",
          responseType: "blob",
          data: rs,
          headers: { ["x-access-token"]: token },
        });
        reqData
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "casesData.xlsx");
            document.body.appendChild(link);
            link.click();
            link.remove();
          })
          .catch((e) => {
            toast.error("Something went wrong while downloading data!");
          });
      } else {
        toast.error("download feature not available yet!.");
      }
    } catch (e) {
      toast.error("Something went wrong while downloading");
    }
  };
  var mrd_requestCount;
  async function GetCount() {
    const result = await notifecationRepo.getNotifectionCount();
    if (result instanceof Result.Success) {
      setCounts((obj: any) => {
        const countNotifications = ({ ...obj, ...result.data.items });
        mrd_requestCount = countNotifications.mrd_request;
        console.log(typeof mrd_requestCount);
        return countNotifications;

      })
      console.log(result.data.items, "result.data.items")
    } else {
      const message = result.message || "Could not load Count";
      toast.error(message);
    }
  }


  function handleDecline(): void {
    setShowPopup(false);
    // throw new Error("Function not implemented.");
  }

  function handleApprove(): void {
    setShowPopup(false);
    // throw new Error("Function not implemented.");
  }

  return (
    <>
      {/* {mrd_requestCount !== 0 && user?.getRole().getTitle() === constants.roleTitle.ca && (
        <span className="head-bell">
          <NotificationPopup message="Approval Request for MRD Charges" onApprove={handleApprove} onDecline={handleDecline} />
        </span>
      )} */}



      {/* <NotificationPopup message="hello"/> */}
      <HomePageContext.Provider
        value={{
          options: {
            category: caseCategoryOptions,
            caseType: caseTypeOptions,
            client: clientOptions,
            states: stateOptions,
            districts: districtOptions,
            user: userOptions,
            userRoles: roleOptions,
            hospital: hospitalOptions,
          },
          filters: {
            AllDistrictS: AllDistrictS,
            setDistrictOptions: setDistrictOptions,
            category: caseCategoryFilter,
            setCategory: setCaseCategoryFilter,
            caseType: caseTypeFilter,
            setCaseType: setCaseTypeFilter,
            state: caseStateFilter,
            setState: setCaseStateFilter,
            client: clientFilter,
            setClient: setClientFilter,

            states: statesFilter,
            setStates: setStatesFilter,

            district: districtFilter,
            setDistrict: setDistrictFilter,
            user: userFilter,
            setUser: setUserFilter,
            userRole: userRoleFilter,
            setUserRole: setUserRoleFilter,
            search: searchFilter,
            setSearch: setSearchFilter,
            claimNumber: claimNumberFilter,
            setClaimNumber: setClaimNumberFilter,
            doctorName: doctorNameFilter,
            setDoctorName: setDoctorNameFilter,
            hospital: hospitalFilter,
            setHospital: setHospitalFilter,
            insuredName: insuredNameFilter,
            setInsuredName: setInsuredNameFilter,
            from: fromFilter,
            setFrom: setFromFilter,
            to: toFilter,
            setTo: setToFilter,
            getByClosedDate: getByClosedDate,
            setGetByClosedDate: setGetByClosedDate,
            getReinvestigation: getReinvestigation,
            setGetReinvestigation: setGetReinvestigation,
            getPushback: getPushback,
            setPushback: setPushback,
            onChange: onChange,
            setHospitalSearch: setHospitalSearch,
            hospitalSearch: hospitalSearch
          },
          count: {
            initiated: stateInitiatedCount,
            generated: stateGeneratedCount,
            open: stateOpenCount,
            reinvestigate: stateReinvestigateCount,
            fold: stateFoldCount,
            checked: stateCheckedCount,
            close: stateCloseCount,
            graded: stateGradedCount,
          },
          navigate: navigateToCaseList,
        }}
      >
        <Button
          style={{
            float: "right",
            marginTop: "20px",
            marginRight: "10px",
            backgroundColor: "rgb(18," + " 33, 214)",
            color: "white",
          }}
          animated
          onClick={async () => await refreshCount()}
        >
          <Button.Content visible>Refresh</Button.Content>
          <Button.Content hidden>
            <Icon name="refresh" />
          </Button.Content>
        </Button>
        <Page title={"Dashboard"}>
          <Grid >
            <Grid.Column floated='left' width={4}>
              <button className="ui blue button" onClick={downloadData}>
                Export as Excel
              </button>
            </Grid.Column>
            <Grid.Column textAlign='right' floated='right' width={12}>
            {user?.getRole().getTitle() !== constants.roleTitle.ca && <> <span className="head-bell">
                {/* <button className="ui blue button" onClick={() => naviugateToNotifications("mrd")}>
                  MRD
                </button> */}
                {/* {Count?.mrd_request > 0 && (
                  <span className="bell-badge">
                    {Count.mrd_request <= 99 ? Count.mrd_request : "99+"}
                  </span>
                )} */}
              </span>
            </>}
            </Grid.Column>

            <Grid.Column textAlign='right' floated='right' width={12} >

              {user?.getRole().getTitle() === constants.roleTitle.ao && <> <span className="head-bell">
                <button className="ui blue button " onClick={() => naviugateToNotification("lodging_expenses_request")}> Lodging expense & Outlocation</button>
                {Count?.lodging_expenses_request > 0 && <span className="bell-badge" >{Count?.lodging_expenses_request <= 99 ? Count?.lodging_expenses_request : "99+"}</span>}
              </span>
                <span className="head-bell">
                  <button className="ui blue button " onClick={() => naviugateToNotification("location_change_request")}>Location Change</button>
                  {Count.location_change_request > 0 && <span className="bell-badge" >{Count?.location_change_request <= 99 ? Count?.location_change_request : "99+"}</span>}
                </span>
                <span className="head-bell">
                  <button className="ui blue button " onClick={() => naviugateToNotification("mrd_request")}>MRD Charges </button>
                  {Count.mrd_request > 0 && <span className="bell-badge" >{Count?.mrd_request <= 99 ? Count?.mrd_request : "99+"}</span>}
                </span>
                <span className="head-bell">
                  <button className="ui blue button " onClick={() => naviugateToNotification("leave_applied")}>Leaves</button>
                  {Count.leave_applied > 0 && <span className="bell-badge" >{Count?.leave_applied <= 99 ? Count?.leave_applied : "99+"}</span>}
                </span>
                <span className="head-bell">
                  <button style={{ margin: "5px" }} className="ui blue button" onClick={() => naviugateToNotification("cancel_case")}>Case Cancelled by investigator</button>
                  {Count.cancel_case > 0 && <span className="bell-badge" >{Count?.cancel_case <= 99 ? Count?.cancel_case : "99+"}</span>}
                </span>
                <span className="head-bell">
                  <button style={{ margin: "5px" }} className="ui blue button" onClick={() => naviugateToNotification("no_work")}>No Work</button>
                  {Count.no_work > 0 && <span className="bell-badge" >{Count?.no_work <= 99 ? Count?.no_work : "99+"}</span>}
                </span>
              </>}

              {user?.getRole().getTitle() === constants.roleTitle.ca && <> <span className="head-bell">
                <button className="ui blue button " onClick={() => naviugateToNotification("case_start")}>Case Start</button>
                {Count.case_start > 0 && <span className="bell-badge" >{Count?.case_start <= 99 ? Count?.case_start : "99+"}</span>}
              </span>

                <span className="head-bell">
                  <button className="ui blue button " onClick={() => naviugateToNotification("close_investigation")}>close Investigation</button>
                  {Count.close_investigation > 0 && <span className="bell-badge" >{Count?.close_investigation <= 99 ? Count?.close_investigation : "99+"}</span>}
                </span>
                <span className="head-bell">
                  <button className="ui blue button " onClick={() => naviugateToNotification("case_close")}>Case Close</button>
                  {Count.case_close > 0 && <span className="bell-badge" >{Count?.case_close <= 99 ? Count?.case_close : "99+"}</span>}
                </span>
              </>}

            </Grid.Column>
          </Grid>

          <Segment>
            <Filters />
          </Segment>
          <Segment loading={loadingCaseCount}>
            <Cards />
          </Segment>
        </Page>
      </HomePageContext.Provider>
    </>
  );

  function effectTriggerLoadCaseCount() {
    setLoadingCaseCount(true);
    source.cancel();
  }

  async function effectLoadCaseCount() {
    if (!loadingCaseCount) return;
    const ro = createCountRequestObject();
    if (!localStorage.getItem("caseCounts")) {
      const result = await caseRepo.getCaseCount(ro, source.token);
      if (result instanceof Result.Success) {
        const items = result.data.items;
        let countItems: { key: string; value: number }[] = [];
        console.log(items, "item")
        items.forEach((it) => {
          countItems.push({ key: it.getState(), value: it.getCount() });
          if (it.getState() === constants.caseStates.initiated)
            setStateInitiatedCount(it.getCount().toString());
          else if (it.getState() === constants.caseStates.generated)
            setStateGeneratedCount(it.getCount().toString());
          else if (it.getState() === constants.caseStates.open)
            setStateOpenCount(it.getCount().toString());
          else if (it.getState() === constants.caseStates.fold)
            setStateFoldCount(it.getCount().toString());
          else if (it.getState() === constants.caseStates.checked)
            setStateCheckedCount(it.getCount().toString());
          else if (it.getState() === constants.caseStates.close)
            setStateCloseCount(it.getCount().toString());
          else if (it.getState() === constants.caseStates.graded)
            setStateGradedCount(it.getCount().toString());
          else if (it.getState() === constants.caseStates.reinvestigate)
            setStateReinvestigateCount(it.getCount().toString());
        });
        localStorage.setItem("caseCounts", JSON.stringify(countItems));
      } else {
        const message = result.message || "Could not load Case Count";
        toast.error(message);
      }
    } else {
      let items = JSON.parse(localStorage.getItem("caseCounts")!);
      items.forEach((it: { key: string; value: string }) => {
        if (it.key === constants.caseStates.initiated)
          setStateInitiatedCount(it.value);
        else if (it.key === constants.caseStates.generated)
          setStateGeneratedCount(it.value);
        else if (it.key === constants.caseStates.open)
          setStateOpenCount(it.value);
        else if (it.key === constants.caseStates.fold)
          setStateFoldCount(it.value);
        else if (it.key === constants.caseStates.checked)
          setStateCheckedCount(it.value);
        else if (it.key === constants.caseStates.close)
          setStateCloseCount(it.value);
        else if (it.key === constants.caseStates.graded)
          setStateGradedCount(it.value);
        else if (it.key === constants.caseStates.reinvestigate)
          setStateReinvestigateCount(it.value);
      });
    }
    setLoadingCaseCount(false);
  }

  function createCountRequestObject() {
    const ro: GetCaseCountRequestObject = {
      showByState: true,
    };
    //
    if (userFilter.trim().length !== 0 && userRoleFilter.trim().length !== 0)
      ro.user = { id: userFilter, role: userRoleFilter };
    if (caseCategoryFilter.trim().length !== 0)
      ro.case_category = caseCategoryFilter;
    if (caseTypeFilter.trim().length !== 0) ro.case_type_id = caseTypeFilter;
    if (caseStateFilter.trim().length !== 0) ro.state = caseStateFilter;
    if (clientFilter.trim().length !== 0) ro.client_id = clientFilter;

    if (statesFilter.trim().length !== 0) ro.location = { state: statesFilter }
    if (districtFilter.trim().length !== 0) statesFilter ? ro.location = { state: statesFilter, district: districtFilter } :
      ro.location = JSON.parse(districtFilter);
    if (claimNumberFilter.trim().length !== 0)
      ro.claim_number = claimNumberFilter;
    if (hospitalFilter.trim().length !== 0) ro.hospital_id = hospitalFilter;
    if (insuredNameFilter.trim().length !== 0)
      ro.insured_name = insuredNameFilter;
    if (doctorNameFilter.trim().length !== 0) ro.doctor_name = doctorNameFilter;
    if (fromFilter.trim().length !== 0) ro.from = fromFilter;
    if (toFilter.trim().length !== 0) ro.to = toFilter;
    if (searchFilter.trim().length !== 0) ro.search = searchFilter;
    if (getByClosedDate == true) ro.get_by_closed_date = true;
    if (getReinvestigation == true) ro.reinvestigation = true;
    if (getPushback == true) ro.pushback = true;


    if (Object.keys(ro).length >= 2) localStorage.removeItem("caseCounts");
    ///
    return ro;
  }

  async function effectLoadEssentialData() {

    if (!loadingEssentialData) return;

    const p1 = roleRepo.getRoles({
      level_greater_than: user?.getRole().getLevel(),
    });
    const p2 = caseCategoryRepo.getCategories();
    const p3 = clientRepo.getClients();
    const p4 = stateRepo.getAll();
    // const p5 = hospitalRepo.getHospitals({ search: hospitalFilter, });
    const p6 = caseTypeRepo.getCaseTypes();
    const p7 = statesDistRepo.getStates();
    const results = await Promise.all([p1, p2, p3, p4,
      //  /p5,
      p6, p7]);

    if (results[0] instanceof Result.Success) {
      setRoleOptions(
        results[0].data.items.map((it) => ({
          text: it.getTitle(),
          value: it.getTitle(),
          key: it.getId(),
        }))
      );
    } else {
      const message = results[0].message || "Could not load User Roles";
      toast.error(message);
    }

    if (results[1] instanceof Result.Success) {
      setCaseCategoryOptions(
        results[1].data.items.map((it) => ({
          text: it.getTitle(),
          value: it.getCode(),
          key: it.getCode(),
        }))
      );
    } else {
      const message = results[1].message || "Could not load Case Categories";
      toast.error(message);
    }

    if (results[2] instanceof Result.Success) {
      setClientOptions(
        results[2].data.items.map((it) => ({
          text: it.getName(),
          value: it.getId(),
          key: it.getId(),
        }))
      );
    } else {
      const message = results[2].message || "Could not load Clients";
      toast.error(message);
    }


    if (results[5] instanceof Result.Success) {
      setStateOptions(
        results[5].data.items.map((it) => ({
          text: it.getName(),
          value: it.getName(),
          key: it.getId(),
        }))
      );
      // console.log("chanfe")
      // let f=createCountRequestObject();
      // if('location' in f)
      // delete f["location"];

      // console.log(createCountRequestObject())

    } else {
      const message = results[5].message || "Could not load State";
      toast.error(message);
    }

    if (results[3] instanceof Result.Success) {
      setDistrictOptions(
        results[3].data.items.map((it, i) => ({
          text: `${it.getDistrict()}, ${it.getState()}`,
          value: JSON.stringify({
            state: it.getState(),
            district: it.getDistrict(),
          }),
          key: i.toString(),
        }))
      );
      setAllDistricts(
        results[3].data.items.map((it, i) => ({
          text: `${it.getDistrict()}, ${it.getState()}`,
          value: JSON.stringify({
            state: it.getState(),
            district: it.getDistrict(),
          }),
          key: i.toString(),
        }))
      )
    } else {
      const message = results[3].message || "Could not load Districts";
      toast.error(message);
    }

    // if (results[4] instanceof Result.Success) {
    // setHospitalOptions(
    //   results[4].data.items.map((it, i) => ({
    //     text: it.getName(),
    //     value: it.getId(),
    //     key: it.getId(),
    //   }))
    // );
    // } else {
    // const message = results[4].message || "Could not load Hospitals";
    // toast.error(message);
    // }

    if (results[4] instanceof Result.Success) {
      setCaseTypeOptions(
        results[4].data.items.map((it, i) => ({
          text: it.getTitle(),
          value: it.getId(),
          key: it.getId(),
        }))
      );
    } else {
      const message = results[4].message || "Could not load caseTypes";
      toast.error(message);
    }
    setLoadingEssentialData(false);
  }

  async function effectLoadUsers() {
    console.log(userRoleFilter, "userRoleFilter")
    if (userRoleFilter.length === 0) return;
    let filter = { role: userRoleFilter, findTeam: false, is_active: true };
    if (
      userRoleFilter == constants.roleTitle.ca ||
      userRoleFilter == constants.roleTitle.tl ||
      userRoleFilter == constants.roleTitle.oh
    )
      filter.findTeam = true;
    const result = await userRepo.getUsers(filter);
    // const result = await userRepo.getUsersByRoleTitle({ role: userRoleFilter })
    if (result instanceof Result.Success) {
      setUserOptions(
        result.data.items.map((it) => ({
          text: it.getName(),
          value: it.getId(),
          key: it.getId(),
        }))
      );
    } else {
      const message = result.message || "Could not load Users";
      toast.error(message);
    }
  }
  // District Function

  async function effectLoadDistrict() {
    if (statesFilter.length === 0) return;
    let filter = { state: statesFilter };

    const result = await statesDistRepo.getDistricts(filter);
    if (result instanceof Result.Success) {
      setDistrictOptions(
        result.data.items.map((it, i) => ({
          text: it,
          value: it,
          key: i.toString(),
        }))
      );
    } else {
      const message = result.message || "Could not load District";
      toast.error(message);
    }
  }

  //  get Hospital
  async function effectLoadHospital(value: any) {
    // if (hospitalFilter.length === 0) return;
    let filter = { search: value };

    const result = await hospitalRepo.getHospitals(filter);
    if (result instanceof Result.Success) {
      setHospitalOptions(
        result.data.items.map((it, i) => ({
          text: it.getName(),
          value: it.getId(),
          key: it.getId(),
        }))
      );
    } else {
      const message = result.message || "Could not load hospital";
      toast.error(message);
    }
  }


  async function onChange(e: any) {

    const value = e.target.value;
    console.log(value, "vbaklduiri")
    if (value.length > 2) {
      setHospitalSearch(value);
      await effectLoadHospital(value);
    } else {
      setHospitalSearch("");
    }
  }


  function naviugateToNotification(type: string) {
    history.push({
      pathname: "/notification",
      state: {
        type: type
      },
    });
  }
  function naviugateToNotifications(type: string) {
    history.push({
      pathname: "/MrdNotifiction",
      state: {
        type: type
      },
    });
  }

  function navigateToCaseList(state: string) {
    history.push({
      pathname: "/case/list",
      state: {
        caseCategoryCode: caseCategoryFilter,
        caseState: state,
        userId: userFilter,
        roleTitle: userRoleFilter,
        clientId: clientFilter,
        caseTypeId: caseTypeFilter,
        states: statesFilter,
        district: districtFilter,
        search: searchFilter,
        hospitalId: hospitalFilter,
        claimNumber: claimNumberFilter,
        insuredName: insuredNameFilter,
        doctorName: doctorNameFilter,
        from: fromFilter,
        to: toFilter,
        reinvestigation: getReinvestigation,
        pushback: getPushback

      },
    });
  }
}

export default HomePage;
