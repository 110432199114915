import DomainModel from './DomainModel'
import number from '../common/validator/number'

class InvoiceSection extends DomainModel {
  constructor(
    private id: string,
    private caseId: string,
    private claimNumber: string,
    private clientId: string,
    private clientName: string,
    private caseTypeId: string,
    private caseTypeTitle: string,
    private closeDate: string,

    private oldDocumentCharges: number,
    private oldApprovedCharges: number,
    private oldExtraCharges: number,

    private invoice: InvoiceSection_Invoice | null
  ) {
    super()
    console.log(invoice,"invoice")
  }

  getId = () => this.id
  getCaseId = () => this.caseId
  getClaimNumber = () => this.claimNumber
  getClientId = () => this.clientId
  getClientName = () => this.clientName
  getCaseTypeId = () => this.caseTypeId
  getCaseTypeTitle = () => this.caseTypeTitle
  getCloseDate = () => this.closeDate

  getOldDocCharges = () => this.oldDocumentCharges
  getOldApprovedCharges = () => this.oldApprovedCharges
  getOldExtraCharges = () => this.oldExtraCharges

  getInvoice = () => this.invoice
}

export class InvoiceSection_Invoice {
  constructor(
    private invoiceNumber: string,
    private invoiceDate: string,
    private professionalFee: number,
    private documentExpense: number,
    private approvedExpense: number ,
    private extraExpense: number,
    private incentive: number,
    private deduction: number,
    private gst: number,
    private invoiceUrl: string | null,

  ) {}
  getInvoiceNumber = () => this.invoiceNumber
  getInvoiceDate = () => this.invoiceDate
  getProfessionalFee = () => this.professionalFee
  getDocumentExpense = () => this.documentExpense
  getApprovedExpense = () => this.approvedExpense
  getExtraExpense = () => this.extraExpense
  getIncentive = () => this.incentive
  getDeduction = () => this.deduction
  getGst = () => this.gst
  getInvoiceUrl = () => this.invoiceUrl
}

export default InvoiceSection
