
import ProtectedAPIRepository from './ProtectedAPIRepository'
import APIResponseModel from './APIResponseModel'
import Result from './Result'
import NotifecatoinDataMapper from '../datamapper/NotifecatoinDataMapper/NotifecatoinDataMapper'
import NotifecationRM from '../responsemodel/NotifecationRM/NotifecationRM'
import NotifecationCountRM from '../responsemodel/NotifecationRM/NotifecationCountRM'

export default class NotifecationRepo extends ProtectedAPIRepository {
    approveNotification(caseId: string, investigatorId: string, typeId: string, amountStatus: string) {
      throw new Error("Method not implemented.")
    }
    private NotifecatoinDataMapper = new NotifecatoinDataMapper()

    getNotifection = async (ro?: GetNotifectionObject) => {
        const response = await this.post<APIResponseModel<NotifecationRM>>('/notification', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<NotifecationRM> = response.data
            if (data.data !== null) {
                const notifecation = data.data.items.map((it) => this.NotifecatoinDataMapper.map(it))
                return new Result.Success({
                    totalItemCount: data.data.total_item_count,
                    items: notifecation,
                })
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }

    getNotifectionMakeRead = async (ro?: any) => {
        const response = await this.post<APIResponseModel<NotifecationRM>>('/notification/read', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<NotifecationRM> = response.data
            if (data.data !== null) {
                const notifecation = data.data.items.map((it) => this.NotifecatoinDataMapper.map(it))
                return new Result.Success({
                    totalItemCount: data.data.total_item_count,
                    items: notifecation,
                })
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }


    getNotifectionMakeReadAll = async (ro?: any) => {
        const response = await this.post<APIResponseModel<NotifecationRM>>('/notification/makeReadAll', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<NotifecationRM> = response.data
            if (data.data !== null) {
                const notifecation = data.data.items.map((it) => this.NotifecatoinDataMapper.map(it))
                return new Result.Success({
                    totalItemCount: data.data.total_item_count,
                    items: notifecation,
                })
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }


    getNotifectionCount = async (ro?: any) => {
        const response = await this.post<APIResponseModel<NotifecationCountRM>>('/notification/countNotification', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<NotifecationCountRM> = response.data
            if (data.data !== null) {
                const notifecation = data.data.items
                return new Result.Success({
                    totalItemCount: data.data.total_item_count,
                    items: data.data.items,
                })
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }

    getNotifectionAllCount = async (ro?: any) => {
        const response = await this.post<APIResponseModel<NotifecationCountRM>>('/notification/countAll', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<NotifecationCountRM> = response.data
            if (data.data !== null) {
                // const notifecation = data.data.items
                return new Result.Success({
                    totalItemCount: data.data.total_item_count,
                    items: data.data,
                })
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }

}

export interface GetNotifectionObject {
    search?: string
    limit?: number
    offset?: number
    unread?: any
    type?: string
   
}
