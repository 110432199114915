import React, { useContext, useEffect, useState } from 'react';
import Page from '../../../layout/Page';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Table from '../../../app/Table';
import ClientListingPageContext from './ClientListingPageContext';
import ClientColumnRenderer from './ClientColumnRenderer';
import Client from '../../../../models/Client';
import RemovalConfirmationForm from '../../../common/RemovalConfirmationForm';
import DisableConfirmationForm from '../../../common/DisableConfirmationForm';
import User from "../../../../models/User";
import UserRepository from '../../../../common/repository/UserRepository'
import constants from '../../../../common/constants';
import Result from '../../../../common/repository/Result';
import { toast } from '../../../common/Toast';

interface userRoleDisableBtn {
  user: User | null;
}

const Layout = () => {
  const ctx = useContext(ClientListingPageContext);
  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState<'remove' | 'disable' | null>(null);
  const [model, setModel] = useState<Client | null>(null);
  const [users,setUsers] = useState<userRoleDisableBtn>();

  const openModal = (type: 'remove' | 'disable', model: Client) => {
    setModalType(type);
    setModel(model);
    setOpen(true);
  };

  useEffect(() => {
    const userRepo = new UserRepository();
    (async() => {
      const getUser = await userRepo.getUserByToken();
      if (getUser instanceof Result.Success) {
        const response = getUser.data;
        setUsers({
          user: response
        });
      } else {
        toast.error("Unable to find to user role.")
      }
    })()
  },[])

  const closeModal = () => {
    setOpen(false);
    setModel(null);
    setModalType(null);
  };

  const handleRemoveConfirm = async () => {
    await ctx.remove(model!); // Perform the remove action
    await ctx.load(); // Refresh the client list
    closeModal(); // Close the modal
  };

  const handleDisableConfirm = async () => {
    await ctx.disable(model!); // Perform the disable action
    closeModal(); // Close the modal
  };

  const columnRenderer = new ClientColumnRenderer(
    (model: Client) => {
      return (
        <Button.Group size="mini">
          <Button style={{ marginRight:  users?.user?.getRole().getTitle() === constants.roleTitle.ceo || users?.user?.getRole().getTitle() === constants.roleTitle.md ? "7px" : "", borderRadius: users?.user?.getRole().getTitle() === constants.roleTitle.ceo || users?.user?.getRole().getTitle() === constants.roleTitle.md ? "5px":"" }} as={Link} to={`/master/client/update/${model.getId()}`}>
            Open
          </Button>
          <Button style={{ marginRight:  users?.user?.getRole().getTitle() === constants.roleTitle.ceo || users?.user?.getRole().getTitle() === constants.roleTitle.md ? "7px" : "", borderRadius: users?.user?.getRole().getTitle() === constants.roleTitle.ceo || users?.user?.getRole().getTitle() === constants.roleTitle.md ? "5px":"" }} onClick={() => openModal('remove', model)} negative>
            Remove
          </Button>
          
          { (users?.user?.getRole().getTitle() === constants.roleTitle.ceo || users?.user?.getRole().getTitle() === constants.roleTitle.md) && (
            <Button style={{ borderRadius: "5px" }}
              onClick={() => openModal('disable', model)}
              positive={model.getdisable ? model.getdisable() === true : true}
              negative={model.getdisable ? model.getdisable() === false : true}
            >
              {model.getdisable() === true ? "Enable" : "Disable"}
            </Button>
          )}
        </Button.Group>
      );
    },
    (obj: any) => obj.name !== undefined
  );

  return (
    <Page title="Clients" description="View clients">
      <Button icon labelPosition="left" color="green" as={Link} to="/master/client/add">
        <Icon name="add" />
        Create New
      </Button>

      <Modal open={open} onClose={closeModal} size="mini" closeIcon closeOnDimmerClick={false} closeOnEscape={false}>
        <Modal.Header>Confirmation</Modal.Header>
        <Modal.Content>
          {modalType === 'remove' ? (
            <RemovalConfirmationForm
              title="Are you sure you want to remove this?"
              remove={handleRemoveConfirm}
              cancel={closeModal}
            />
          ) : (
            <DisableConfirmationForm
              title={`Are you sure you want to ${model?.getdisable() === true ? "enable" : "disable"} this client?`}
              actionText={model?.getdisable() === true ? "Enable" : "Disable"}
              disable={handleDisableConfirm}
              cancel={closeModal}
            />
          )}
        </Modal.Content>
      </Modal>

      <Table
        renderer={columnRenderer}
        data={ctx.clients}
        totalDataCount={ctx.count}
        loading={ctx.loading}
        load={ctx.load}
        onRowsPerPageChange={(page, rowsPerPage) => {
          const offset = (page - 1) * rowsPerPage;
          const limit = offset + rowsPerPage;
          ctx.setLimit(limit);
          ctx.setOffset(offset);
          ctx.load();
        }}
        onSearch={(text) => {
          ctx.setFilter(text);
          ctx.setOffset(0);
          ctx.setCount(0);
          ctx.load();
        }}
        onClear={() => {
          ctx.setFilter('');
          ctx.setOffset(0);
          ctx.setCount(0);
          ctx.load();
        }}
      />
    </Page>
  );
};

export default Layout;
