import React, { useState } from "react";
import { Menu, Button, Input } from "semantic-ui-react";
import LocalStorage from "../../../lib/LocalStorage";
import axios from "axios";
import { toast } from "../../common/Toast";
import download from "js-file-download";
import config from "../../../common/repository/config";
export interface TableActionsProps {
  exportPath?: string[];
  onDownload?(): void;
  onSearch(filter: string): void;
  onClear(): void;
  DownloadBtn?: boolean
}

export interface PrivateTableActionProps extends TableActionsProps {
  resetTable(): void;
}

const TableActions = ({
  exportPath,
  resetTable,
  onSearch,
  onDownload,
  onClear,
  DownloadBtn
}: PrivateTableActionProps) => {
  const [filter, setFilter] = useState("");
  let [downloadedData, setDownloadedData] = useState("");
  //console.log("Export path: ", onDownload? onDownload(): "");

  const downloadData = async () => {
    let rs = onDownload ? onDownload() : {};
    if (!rs) rs = {};
    const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
    try {
      if (Object.entries(rs).length > 0) {
        toast.success("Download will start shortly!");
        let reqData = axios({
          url: config.apiBaseURL + `/download`,
          method: "POST",
          responseType: "blob",
          data: rs,
          headers: { ["x-access-token"]: token },
          onDownloadProgress: (progressEvent:any) => {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            console.log(progressEvent.lengthComputable)

            let percentage = `${Math.round(
              progressEvent.loaded / 1024 / 1024
            )} MB Downloaded`;
            setDownloadedData(percentage);
            console.log(downloadedData)
          },
        });
        reqData
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "data.zip");
            document.body.appendChild(link);
            link.click();
            link.remove();
            setDownloadedData("");
          })
          .catch((e) => {
            toast.error("Something went wrong while downloading data!");
          });
      } else {
        toast.error("download feature not available yet!.");
      }
    } catch (e) {
      toast.error("Something went wrong while downloading");
    }
  };

  return (
    <>
      <Menu>
        {DownloadBtn ? "" : <Menu.Item>
          {/*<Button as='a' href={exportPath || '#'} target='_blank' color='blue'>*/}
          {/*    Export CSV*/}
          {/*</Button>*/}
          <Button onClick={downloadData}>
            {downloadedData != "" ? downloadedData : "Download All"}
          </Button>
        </Menu.Item>}

        <Menu.Item position="right">
          <Input
            placeholder="Filter"
            value={filter}
            onChange={(_, { value }) => setFilter(value)}
            style={{ marginRight: 4 }}
          />

          <Button.Group size="mini">
            <Button
              color="green"
              onClick={() => {
                resetTable();
                onSearch(filter);
              }}
            >
              Search
            </Button>

            <Button.Or></Button.Or>

            <Button
              onClick={() => {
                setFilter("");
                resetTable();
                onClear();
              }}
            >
              Clear
            </Button>
          </Button.Group>
        </Menu.Item>
      </Menu>
    </>
  );
};

/* popup work */
/* popup work */

export default TableActions;