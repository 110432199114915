import React, { useState, useContext, useEffect } from "react";
import {
  DropdownItemProps,
  Form,
  Grid,
  Radio,
  Segment,
} from "semantic-ui-react";
import axios from "axios";
import LocalStorage from "../../../../lib/LocalStorage";
import ClientRepository from "../../../../common/repository/ClientRepository";
import Result from "../../../../common/repository/Result";
import { toast } from "../../../common/Toast";
import DateTimePicker from "../../../app/DateTimePicker";
import config from "../../../../common/repository/config";

function PaymentTemplateDownloadForm({ onSuccess }: Props) {
  const [clientOptions, setClientOptions] = useState<DropdownItemProps[]>([]);
  const [clientId, setClientId] = useState("");
  const [docReceived, setDocReceived] = useState(false);
  const [tds, setTds] = useState<number>(0);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const clientRepo = new ClientRepository();

  const loadEssentialData = async () => {
    const userData = await clientRepo.getClients({});
    if (userData instanceof Result.Success) {
      setClientOptions(
        userData.data.items.map((it) => ({
          text: it.getName(),
          value: it.getId(),
          key: it.getId(),
        }))
      );
    } else {
      const message = userData.message || "Could not load Users";
      toast.error(message);
    }
  };

  useEffect(() => {
    if (clientOptions.length == 0) {
      loadEssentialData();
    }
  }, []);

  return (
    <Form>
      <Form.Dropdown
        label="Choose Client"
        search
        selection
        placeholder="Select Client"
        options={clientOptions}
        value={clientId}
        onChange={(_, { value }) => setClientId(value as string)}
      />
      {/*<Segment >*/}
      {/*    <Radio toggle label={'Document Received'}*/}
      {/*           checked={docReceived}*/}
      {/*           onChange={() => {*/}
      {/*               setDocReceived(!docReceived)*/}
      {/*           }}/>*/}
      {/*</Segment>*/}
      <Form.Input
        label="TDS (%)"
        type={"number"}
        placeholder="Enter TDS"
        value={tds}
        onChange={(_, { value }) => setTds(Number(value))}
        // onKeyDown={(e:any)=>{if(e.keyCode==13)ctx.filters.setClaimNumber(claimNumber)}}
      />

      <Form.Input label="From Date">
        <DateTimePicker
          placeholder="Select From Date"
          type="date"
          value={fromDate}
          onChange={(val) => setFromDate(val)}
        />
      </Form.Input>
      <Form.Input label="To Date" disabled={fromDate.length == 0}>
        <DateTimePicker
          placeholder="Select To Date"
          type="date"
          value={toDate}
          onChange={(val) => setToDate(val)}
        />
      </Form.Input>

      <Form.Button
        positive
        disabled={clientId.length == 0 || tds <= 0}
        onClick={downloadTemplate}
      >
        Download
      </Form.Button>
    </Form>
  );

  async function downloadTemplate() {
    toast.success("Download will start shortly!");
    const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
    const response = await axios({
      url: config.apiBaseURL+`/billing/payment/template`,
      method: "POST",
      data: {
        client_id: clientId,
        docReceived: docReceived,
        tds: tds,
        from: fromDate.length > 0 ? fromDate : undefined,
        to: toDate.length > 0 ? toDate : undefined,
      },
      responseType: "blob",
      headers: { ["x-access-token"]: token },
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "paymentTemplate.xlsx");
    document.body.appendChild(link);
    link.click();
    link.remove();

    if (onSuccess) onSuccess();
  }
}

interface Props {
  onSuccess?(): void;
}

export default PaymentTemplateDownloadForm;