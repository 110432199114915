import ProtectedAPIRepository from './ProtectedAPIRepository'
import APIResponseModel from './APIResponseModel'
import Result from './Result'
import ClientDataMapper from '../datamapper/ClientDataMapper'
import ClientRM from '../responsemodel/ClientRM'
import { toast } from '../../components/common/Toast'

class ClientRepository extends ProtectedAPIRepository {
  private dataMapper = new ClientDataMapper()

  getClients = async (ro?: GetClientsRequestObject) => {
    const response = await this.post<APIResponseModel<ClientRM>>('/client', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<ClientRM> = response.data
      if (data.data !== null) {
        const clients = data.data.items.map((it) => this.dataMapper.map(it))
        return new Result.Success({
          totalItemCount: data.data.total_item_count,
          items: clients,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  getCompanies = async (ro?: Omit<GetClientsRequestObject, 'type'>) => {
    const requestObject: GetClientsRequestObject = { ...ro, type: 'Company' }
    return this.getClients(requestObject)
  }

  getTPAs = async (ro?: Omit<GetClientsRequestObject, 'type'>) => {
    const requestObject: GetClientsRequestObject = { ...ro, type: 'Tpa' }
    return this.getClients(requestObject)
  }

  addClient = async (ro: AddClientRequestObject) => {
    const formData = this.constructFormData(ro)
    const response = await this.put<APIResponseModel<ClientRM>>('/client', formData)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<ClientRM> = response.data
      if (data.data !== null) {
        const clients = this.dataMapper.map(data.data.items[0])
        return new Result.Success(clients)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  updateClient = async (ro: UpdateClientRequestObject) => {
    const formData = this.constructFormData(ro)
    const response = await this.patch<APIResponseModel<ClientRM>>('/client', formData)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<ClientRM> = response.data
      if (data.data !== null) {
        const client = this.dataMapper.map(data.data.items[0])
        return new Result.Success(client)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  updateClaimTypes = async (ro: UpdateClaimTypesRequestObject) => {
    const response = await this.patch<APIResponseModel<ClientRM>>('/client/claimType', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<ClientRM> = response.data
      if (data.data !== null) {
        const client = this.dataMapper.map(data.data.items[0])
        return new Result.Success(client)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  updateBillingAddress = async (ro: UpdateBillingAddressRequestObject) => {
    const response = await this.patch<APIResponseModel<ClientRM>>('/client/branch', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<ClientRM> = response.data
      if (data.data !== null) {
        const client = this.dataMapper.map(data.data.items[0])
        return new Result.Success(client)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  removeClient = async (clientId: string) => {
    const response = await this.delete<APIResponseModel<null>>(`/client/${clientId}`)
    return response
  }

  disableClient = async (clientId: string, ro:UpdateClientDisbaleObject) => {
    const response = await this.post<APIResponseModel<ClientRM>>(`/client/${clientId}`,ro);
    if (response instanceof Result.Success) {
      const data: APIResponseModel<ClientRM> = response.data;
      if ( data.message != null ) {
        toast.success(data.message);
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response;
    }
  }
}

export default ClientRepository

export interface GetClientsRequestObject {
  search?: string
  type?: string
  limit?: number
  offset?: number
  id?: string
  name?: string
}

export interface UpdateClientDisbaleObject {
  disable: boolean
}

export interface AddClientRequestObject {
  name: string
  type: string
  mou_expiry: string
  mou_document?: File
  handler: string
  claim_types?: {
    title: string
    fee: number
    gst_percentage: number
    hsn_code: string
  }[]
  branches?: {
    state: string
    gst_number: string
    locations?: { city: string; address: string }[]
  }[]
}

export interface UpdateClientRequestObject {
  id: string
  name: string
  type: string
  mou_expiry: string
  mou_document?: File
  handler: string
  claim_types?: {
    title: string
    fee: number
    gst_percentage: number
    hsn_code: string
  }[]
  branches?: {
    state: string
    gst_number: string
    locations?: { city: string; address: string }[]
  }[]
}

export interface UpdateClaimTypesRequestObject {
  id: string
  claim_types: {
    title: string
    fee: number
    gst_percentage: number
    hsn_code: string
  }[]
}

export interface UpdateBillingAddressRequestObject {
  id: string
  branches: {
    state: string
    gst_number: string
    locations?: {
      city: string
      address: string
    }[]
  }[]
}
