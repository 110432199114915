import ActionColumnRenderer from '../../../app/Table/renderer/ActionColumnRenderer'
import InvoiceSection from '../../../../models/InvoiceSection'

export default class InvoiceSectionColumnRenderer extends ActionColumnRenderer<InvoiceSection> {
  getColumns() {
    let invoicePresent = false

    return [
      {
        name: 'Claim Number',
        selector: 'claimNumber',
      },
      {
        name: 'Client Name',
        selector: 'clientName',
      },
      {
        name: 'Close Date',
        selector: 'closeDate',
      },
      {
        name: 'Case Type',
        selector: 'caseTypeTitle',
      },
      {
        name: 'Professional Fee',
        selector: 'invoice',
        format: (row: InvoiceSection) => {
          if (row.getInvoice() == null) return 'NA'
          return row.getInvoice()!.getProfessionalFee()
        },
      },
      {
        name: 'Document Expense',
        selector: 'invoice',
        format: (row: InvoiceSection) => {
          if (row.getInvoice() == null) return 'NA'
          return row.getInvoice()!.getDocumentExpense()
        },
      },
      {
        name: 'Approved Expense(Invoice)',
        selector: 'invoice',
        format: (row: InvoiceSection) => {
          if (row.getInvoice() == null) return 'NA'
          return row.getInvoice()!.getApprovedExpense()
        },
      },
      // {
      //     name: "Extra Expense(Investigator)",
      //     selector: 'invoice',
      //     format: (row: InvoiceSection) => {
      //         if(row.getInvoice() == null) return "NA"
      //         return row.getInvoice()!.getExtraExpense();
      //     }
      // },
      {
        name: 'Incentive',
        selector: 'invoice',
        format: (row: InvoiceSection) => {
          if (row.getInvoice() == null) return 'NA'
          return row.getInvoice()!.getIncentive()
        },
      },
      {
        name: 'Deduction',
        selector: 'invoice',
        format: (row: InvoiceSection) => {
          if (row.getInvoice() == null) return 'NA'
          return row.getInvoice()!.getDeduction()
        },
      },
      {
        name: 'GST Amount',
        selector: 'invoice',
        format: (row: InvoiceSection) => {
          if (row.getInvoice() == null) return 'NA'
          return row.getInvoice()!.getGst()
        },
      },
      {
        name: 'Invoice Amount',
        selector: 'invoice',
        format: (row: InvoiceSection) => {
          if (row.getInvoice() == null) return 'NA'
          let total =
            row.getInvoice()!.getProfessionalFee() +
            row.getInvoice()!.getIncentive() +
            row.getInvoice()!.getDocumentExpense()  +
            row.getInvoice()!.getApprovedExpense() +
            row.getInvoice()!.getGst() +
            row.getInvoice()!.getExtraExpense() -
            row.getInvoice()!.getDeduction()

          return total
        },
      },
      {
        name: 'Invoice Number',
        selector: 'invoice',
        format: (row: InvoiceSection) => {
          if (row.getInvoice() == null) return 'NA'
          return row.getInvoice()!.getInvoiceNumber()
        },
      },
      {
        name: 'Invoice Date',
        selector: 'invoice',
        format: (row: InvoiceSection) => {
          if (row.getInvoice() == null) return 'NA'
          return row.getInvoice()!.getInvoiceDate()
        },
      },
      {
        name: 'Receipt -||- Action',
        selector: 'id',
        cell: this.renderActionColumn,
      },
    ]
  }
}
