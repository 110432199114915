import React from "react";
import ReactDOM from "react-dom";
import { Segment, Header, Message, Placeholder, Grid, Button, DropdownItemProps, Modal, Form, TransitionablePortal, Tab, Icon, Label } from "semantic-ui-react";
import Result from "../../../../../../common/repository/Result";
import { toast } from "../../../../../common/Toast";
import { InvestigatorCaseOutcomeInputValue } from "../InvestigatorCaseOutcomeInput";
import AllocatedInvestigator from "../../../../../../models/AllocatedInvestigator";
import InvestigatorCard from "./InvestigatorCard";
import constants from "../../../../../../common/constants";
import CaseTypeRepository from "../../../../../../common/repository/CaseTypeRepository";
import CaseType from "../../../../../../models/CaseType";
import CompleteCase from "../../../../../../models/CompleteCase";
import CaseOptionalFieldsInputForm from "./CaseOptionalFieldsInputForm";
import CaseRepository from "../../../../../../common/repository/CaseRepository";
import RequirePermission from "../../../../../base/RequirePermission";
import permissions from "../../../../../../common/permissions";
import CaseService from "../../../../../../models/CaseService";
import CaseServiceData from "../../../../../../models/CaseServiceData";
import AttendentInfoDataTable from "./CaseServiceDetailsTable/AttendentInfoDataTable";
import AttendentInfoData from "../../../../../../models/CaseServiceData/AttendentInfoData";
import DoctorPathologistsData from "../../../../../../models/CaseServiceData/DoctorPathologistsData";
import HospitalInfrastructureData from "../../../../../../models/CaseServiceData/HospitalInfrastructureData";
import InsuredInfoData from "../../../../../../models/CaseServiceData/InsuredInfoData";
import TreatingDoctorsData from "../../../../../../models/CaseServiceData/TreatingDoctorsData";
import DoctorPathologistsDataTable from "./CaseServiceDetailsTable/DoctorPathologistsDataTable";
import HospitalInfrastructureDataTable from "./CaseServiceDetailsTable/HospitalInfrastructureDataTable";
import InsuredInfoDataTable from "./CaseServiceDetailsTable/InsuredInfoDataTable";
import TreatingDoctorsDataTable from "./CaseServiceDetailsTable/TreatingDoctorsDataTable";
import RepudiationGround from "../../../../../../models/RepudiationGround";
import RepudiationGroundRepository from "../../../../../../common/repository/RepudiationGroundRepository";
import QCData from "../../../../../../models/QCData";
import URLResource from "../../../../../../models/URLResource";
import FilesPopup from "../../FilesPopup";

interface Props {
  model: CompleteCase;
  loggedUserTitle: string;
  updateModel(model: CompleteCase): void;
  CaseServiceData: CaseServiceData;
}

interface State {
  loading: boolean;
  loadingAllocatedInvestigators: boolean;
  loadingCaseTypes: boolean;
  caseTypes: CaseType[];
  allocatedInvestigators: AllocatedInvestigator[];
  caseOutcome: string;
  caseType: any | string;
  caseTypeModel: CaseType;
  report: File | null;
  Query_Trigger_Document: File | null;
  repudiation_Ground: string;
  repudiationEvidence: File[];
  loadibgCaseServiceData: boolean;
  caseService: CaseService[];
  caseServiceData: CaseServiceData[];
  AttendentInfoData: AttendentInfoData[];
  doctorPathologistsData: DoctorPathologistsData[];
  hospitalInfrastructureData: HospitalInfrastructureData[];
  InsuredInfoData: InsuredInfoData[];
  treatingDoctorsData: TreatingDoctorsData[];
  form_investigators: AllocatedInvestigator[];
  case_overallOutcome: string;
  case_investigationData: (InvestigatorCaseOutcomeInputValue | null)[];
  isInvestigatorsOutcomeRepudiation: boolean;
  evidenceSizeExceedsMessage: string;
  evidenceSizeExceedsPopupVisible: boolean;
  repudiationGroundList: RepudiationGround[];
  changePopUp: any;
  confirmation: any;
  selectedCaseType: any;
  closeCaseTypeModel: any;
  newCaseCategoryCode: any;
  repudiationEvidencePopupOpen: boolean;

}

class ActionFold extends React.Component<Props, State> {
  private caseRepo = new CaseRepository();
  private caseTypeRepo = new CaseTypeRepository();
  private RepudiationGroundRepository = new RepudiationGroundRepository();
  private portalRoot: HTMLElement | null = null;
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      loadingAllocatedInvestigators: true,
      loadingCaseTypes: true,

      caseTypes: [],
      allocatedInvestigators: [],
      caseOutcome: "",
      caseType: "", //props.model.getType().getId()
      caseTypeModel: props.model.getType(),
      report: null,
      Query_Trigger_Document: null,
      repudiation_Ground: "",
      repudiationEvidence: [],
      form_investigators: [],
      case_overallOutcome: "",
      case_investigationData: [],
      isInvestigatorsOutcomeRepudiation: false,
      evidenceSizeExceedsMessage: "",
      evidenceSizeExceedsPopupVisible: false,
      loadibgCaseServiceData: true,
      caseService: [],
      caseServiceData: [],
      AttendentInfoData: [],
      doctorPathologistsData: [],
      hospitalInfrastructureData: [],
      InsuredInfoData: [],
      treatingDoctorsData: [],
      repudiationGroundList: [],
      changePopUp: false,
      confirmation: false,
      selectedCaseType: "",
      closeCaseTypeModel: false,
      newCaseCategoryCode: props.model.getCategory().getCode(),
      repudiationEvidencePopupOpen: false,
    };
    // { console.log(this.props.model.getType().getTitle(), "ttttttt") }
    // { console.log(this.state.caseTypeModel.getTitle(), "this.state.selectedCaseType") }
  }

  componentDidMount() {
    this.getAllocatedInvestigators();
    this.getCaseTypes();
    this.getServices();
    // this.getServicesData();

    this.getAttendentInfoData();
    this.getDoctorPathologistsData();
    this.getHospitalInfrastructureData();
    this.getInsuredInfoData();
    this.getTreatingDoctorsData();
    this.getRepudiationGround();
    this.portalRoot = document.getElementsByTagName("body")[0];

  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.model != this.props.model) {
      this.getAllocatedInvestigators();
    }

    if (
      prevProps.model.getType().getId() !== this.props.model.getType().getId()
    )
      this.setState({
        caseType: this.props.model.getType().getId(),
        caseTypeModel: this.props.model.getType(),
      });

    // console.log(this.state.caseTypeModel, "caseTypeModel")

    // for(let i=0; i< this.state.form_investigators.length; i++){
    //     let it = this.state.form_investigators[i];
    //     if(it.getOutcome() !=null && !this.state.isInvestigatorsOutcomeRepudiation){
    //         if(it.getOutcome() == constants.caseOutcome.repudiation) this.setState({isInvestigatorsOutcomeRepudiation: true});
    //         break;
    //     }
    // }
    // if(this.state.isInvestigatorsOutcomeRepudiation) this.setState({caseOutcome: constants.caseOutcome.repudiation})
  }

  private getRepudiationGround = () => {
    this.setState({ loadingCaseTypes: true }, async () => {
      const result =
        await this.RepudiationGroundRepository.getRepuditationGround();
      if (result instanceof Result.Success) {
        const repudiationGroundList = result.data.items;
        this.setState({ repudiationGroundList, loadingCaseTypes: false });
      } else {
        this.setState({ loadingCaseTypes: false }, () => {
          const message = result.message || "Could not load Case Types";
          toast.error(message);
        });
      }
    });
  };

  public getAllocatedInvestigators = async () => {
    this.setState({ loadingAllocatedInvestigators: true }, async () => {
      const { model } = this.props;
      const result = await this.caseRepo.getAllocatedInvestigators({
        case_id: model.getId(),
      });
      if (result instanceof Result.Success) {
        const investigators = result.data.items;
        const investigationData = new Array(investigators.length).fill(null);
        await this.setState({
          loadingAllocatedInvestigators: false,
          form_investigators: investigators,
          case_investigationData: investigationData,
        });
      } else {
        this.setState({ loadingAllocatedInvestigators: false }, () => {
          const message =
            result.message || "Could not load Allocated Investigators";
          toast.error(message);
        });
      }
    });
  };

  private getCaseTypes = () => {
    this.setState({ loadingCaseTypes: true }, async () => {
      const result = await this.caseTypeRepo.getCaseTypes();
      if (result instanceof Result.Success) {
        const caseTypes = result.data.items;
        this.setState({ caseTypes, loadingCaseTypes: false });
      } else {
        this.setState({ loadingCaseTypes: false }, () => {
          const message = result.message || "Could not load Case Types";
          toast.error(message);
        });
      }
    });
  };

  private updateAllocatedInvestigator = (model: AllocatedInvestigator) => {
    const investigators = this.state.form_investigators;
    const index = investigators.findIndex((it) => it.getId() === model.getId());
    if (index === -1) return;

    investigators[index] = model;
    this.setState({ form_investigators: [...investigators] });
  };

  private removeAllocatedInvestigator = (model: AllocatedInvestigator) => {
    const investigators = this.state.form_investigators;
    const index = investigators.findIndex((it) => it.getId() === model.getId());
    if (index === -1) return;

    investigators.splice(index, 1);
    this.setState({ form_investigators: [...investigators] });
  };

  private getServices = () => {
    this.setState({ loadibgCaseServiceData: true }, async () => {
      const result = await this.caseRepo.getCaseServices(
        this.props.model.getId()
      );
      // console.log(result, "result")
      if (result instanceof Result.Success) {
        const caseService = result.data.items;
        this.setState({ caseService, loadibgCaseServiceData: false });
      } else {
        this.setState({ loadibgCaseServiceData: false }, () => {
          const message = result.message || "Could not load Services";
          toast.error(message);
        });
      }
    });
  };

  private getAttendentInfoData = () => {
    this.setState({ loadibgCaseServiceData: true }, async () => {
      const result = await this.caseRepo.getAttendentInfoData(
        this.props.model.getId()
      );
      if (result instanceof Result.Success) {
        const AttendentInfoData = result.data.items;
        this.setState({ AttendentInfoData, loadibgCaseServiceData: false });
      } else {
        this.setState({ loadibgCaseServiceData: false }, () => {
          const message = result.message || "Could not AttendentInfo Data";
          toast.error(message);
        });
      }
    });
  };

  private getDoctorPathologistsData = () => {
    this.setState({ loadibgCaseServiceData: true }, async () => {
      const result = await this.caseRepo.getDoctorPathologistsData(
        this.props.model.getId()
      );
      if (result instanceof Result.Success) {
        const doctorPathologistsData = result.data.items;
        this.setState({
          doctorPathologistsData,
          loadibgCaseServiceData: false,
        });
      } else {
        this.setState({ loadibgCaseServiceData: false }, () => {
          const message = result.message || "Could not AttendentInfo Data";
          toast.error(message);
        });
      }
    });
  };

  private getHospitalInfrastructureData = () => {
    this.setState({ loadibgCaseServiceData: true }, async () => {
      const result = await this.caseRepo.getHospitalInfrastructureData(
        this.props.model.getId()
      );
      if (result instanceof Result.Success) {
        const hospitalInfrastructureData = result.data.items;
        this.setState({
          hospitalInfrastructureData,
          loadibgCaseServiceData: false,
        });
      } else {
        this.setState({ loadibgCaseServiceData: false }, () => {
          const message = result.message || "Could not AttendentInfo Data";
          toast.error(message);
        });
      }
    });
  };

  private getInsuredInfoData = () => {
    this.setState({ loadibgCaseServiceData: true }, async () => {
      const result = await this.caseRepo.getInsuredInfoData(
        this.props.model.getId()
      );
      if (result instanceof Result.Success) {
        const InsuredInfoData = result.data.items;
        this.setState({ InsuredInfoData, loadibgCaseServiceData: false });
      } else {
        this.setState({ loadibgCaseServiceData: false }, () => {
          const message = result.message || "Could not AttendentInfo Data";
          toast.error(message);
        });
      }
    });
  };

  private getTreatingDoctorsData = () => {
    this.setState({ loadibgCaseServiceData: true }, async () => {
      const result = await this.caseRepo.getTreatingDoctorsData(
        this.props.model.getId()
      );
      if (result instanceof Result.Success) {
        const treatingDoctorsData = result.data.items;
        this.setState({ treatingDoctorsData, loadibgCaseServiceData: false });
      } else {
        this.setState({ loadibgCaseServiceData: false }, () => {
          const message = result.message || "Could not AttendentInfo Data";
          toast.error(message);
        });
      }
    });
  };

  // private getServicesData = () => {
  //   this.setState({ loadibgCaseServiceData: true }, async () => {
  //     const result = await this.caseRepo.getCaseServicesData(this.props.model.getId());
  //     // console.log(result, "result2")
  //     if (result instanceof Result.Success) {
  //       const caseServiceData: any = result.data.items[0]['AttendentInfoData'];
  //       // const AttendentInfoData:any = result.data.items[0]['H'];
  //       // const AttendentInfoData:any = result.data.items[0]['AttendentInfoData'];
  //       // const AttendentInfoData:any = result.data.items[0]['AttendentInfoData'];
  //       // const AttendentInfoData:any = result.data.items[0]['AttendentInfoData'];
  //       // console.log(" test", caseServiceData.AttendentInfoData)
  //       this.setState({ caseServiceData, loadibgCaseServiceData: false });
  //     } else {
  //       this.setState({ loadibgCaseServiceData: false }, () => {
  //         const message = result.message || "Could not load Services Data ";
  //         toast.error(message);
  //       });
  //     }
  //   });
  // };

  private enableReinvestigation = (model: AllocatedInvestigator) => {
    this.setState({ loadingAllocatedInvestigators: true }, async () => {
      const result = await this.caseRepo.enableReInvestigation(model.getId());
      if (result instanceof Result.Success) {
        const investigators = result.data.items;
        const investigationData = new Array(investigators.length).fill(null);
        this.setState({
          loadingAllocatedInvestigators: false,
          form_investigators: investigators,
          case_investigationData: investigationData,
        });
      } else {
        this.setState({ loadingAllocatedInvestigators: false }, () => {
          const message =
            result.message || "Could not load Allocated Investigators";
          toast.error(message);
        });
      }
    });
  };

  private formatBytes = (a: number, b = 2) => {
    if (0 === a) return "0 Bytes";
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return (
      parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
      " " +
      ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
    );
  };

  private onSubmit = () => {
    this.setState({ loading: true }, async () => {
      const { model, updateModel } = this.props;
      let flag = true;
      if (
        !(
          this.props.loggedUserTitle === constants.roleTitle.ceo ||
          this.props.loggedUserTitle === constants.roleTitle.md ||
          this.props.loggedUserTitle === constants.roleTitle.admin
        )
      ) {
        const evidenceSizeResult = await this.caseRepo.getEvidenceSize(
          model.getClaimNumber()
        );
        if (evidenceSizeResult instanceof Result.Success) {
          const c = evidenceSizeResult.data[0] as {
            aws_evidence_size: number;
            saved_evidence_size: number;
          };

          if (c.aws_evidence_size > c.saved_evidence_size) {
            let diff = this.formatBytes(
              c.aws_evidence_size - c.saved_evidence_size
            );

            this.setState({
              loading: false,
              evidenceSizeExceedsPopupVisible: true,
              evidenceSizeExceedsMessage: `Evidence size is ${diff} more than the limit`,
            });

            flag = false;
          }
        } else {
          this.setState({ loading: false }, () => {
            const message =
              evidenceSizeResult.message || "Something went wrong";
            toast.error(message);
          });
        }
      }

      if (flag) {
        let result: any
        if (this.state.caseOutcome == constants.caseOutcome.repudiation) {
          result = await this.caseRepo.foldCase({
            case_id: model.getId(),
            outcome: this.state.caseOutcome,
            case_type: this.state.selectedCaseType,
            //case_category: this.state.caseTypeModel.getCaseCategoryCode(),
            case_category: this.state.newCaseCategoryCode,
            outcome_report: this.state.report!,
            repudiation_ground: this.state.repudiation_Ground,
            // query_trigger_document: this.state.Query_Trigger_Document!,
            repudiation_evidences: this.state.repudiationEvidence,
          });
        }
        if (this.state.caseOutcome == constants.caseOutcome.inconclusive) {
          result = await this.caseRepo.foldCase({
            case_id: model.getId(),
            outcome: this.state.caseOutcome,
            case_type: this.state.selectedCaseType,
            //case_category: this.state.caseTypeModel.getCaseCategoryCode(),
            case_category: this.state.newCaseCategoryCode,
            outcome_report: this.state.report!,
            // repudiation_ground: this.state.repudiation_Ground,
            query_trigger_document: this.state.Query_Trigger_Document!,
            // repudiation_evidences: this.state.repudiationEvidence,
          });
        }
        if (this.state.caseOutcome == constants.caseOutcome.genuine) {

          result = await this.caseRepo.foldCase({
            case_id: model.getId(),
            outcome: this.state.caseOutcome,
            case_type: this.state.selectedCaseType,
            //case_category: this.state.caseTypeModel.getCaseCategoryCode(),
            case_category: this.state.newCaseCategoryCode,
            outcome_report: this.state.report!,
            // repudiation_ground: this.state.repudiation_Ground,
            // query_trigger_document: this.state.Query_Trigger_Document!,
            // repudiation_evidences: this.state.repudiationEvidence,
          });
        }

        if (result instanceof Result.Success) {
          this.setState({ loading: false }, () => {
            const c = result.data as CompleteCase;
            toast.success("Successfully closed Case");
            updateModel(c);
          });
        } else {
          this.setState({ loading: false }, () => {
            const message = result.message || "Something went wrong";
            toast.error(message);
          });
        }
      }
    });
  };

  render() {
    const { model, updateModel } = this.props;

    let report = null;
    let filalReports = null;
    let repudiationEvidence: any[] = [];
    let queryTriggerDocument = null;

    if (this.props.model.getReinvestigation()) {
      report = this.props.model.getReport();
      filalReports = this.props.model.getFinalReport()

      console.log(model.getQueryTriggerDocument(), "updsauyrer")
      if (model.getRepudiationEvidence())
        repudiationEvidence = this.props.model.getRepudiationEvidence();

      if (this.props.model.getQueryTriggerDocument() !== null) queryTriggerDocument = this.props.model.getQueryTriggerDocument();
    }




    { console.log(this.props.model.getId(), "model") }

    let ServiceTab = [
      {
        menuItem: "HOSPITAL INFRASTRUCTURE",
        render: () => (
          <Tab.Pane attached={false}>
            <HospitalInfrastructureDataTable
              data={this.state.hospitalInfrastructureData}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "TREATING DOCTOR",
        render: () => (
          <Tab.Pane attached={false}>
            <TreatingDoctorsDataTable data={this.state.treatingDoctorsData} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "PATHOLOGIST",
        render: () => (
          <Tab.Pane attached={false}>
            <DoctorPathologistsDataTable
              data={this.state.doctorPathologistsData}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "INSURED INFO",
        render: () => (
          <Tab.Pane attached={false}>
            <InsuredInfoDataTable data={this.state.InsuredInfoData} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "ATTENDANT INFO",
        render: () => (
          <Tab.Pane attached={false}>
            <AttendentInfoDataTable data={this.state.AttendentInfoData} />
          </Tab.Pane>
        ),
      },
    ];

    let caseServiceData = this.state.caseService
      .map((it) => it.getServiceData())
      .splice(0, ServiceTab.length);
    let newArr: any = [];
    ServiceTab.forEach((item) => {
      caseServiceData.forEach((value) => {
        if (item.menuItem == value) newArr.push(item);
      });
    });
    const CaseServiceTab = () => (
      <Tab menu={{ pointing: true }} panes={newArr} />
    );

    const RepudiationGround = this.state.repudiationGroundList.map((it) => {
      return {
        text: it.getRepudiationGround(),
        value: it.getId(),
        key: it.getId(),
      };
    });

    let allocatedInvestigators: React.ReactNode | null = null;
    if (this.state.loadingAllocatedInvestigators)
      allocatedInvestigators = <InvestigatorsLoadingState />;
    else {
      allocatedInvestigators = this.state.form_investigators.map((it) => {
        console.log(it, "it1")
        return (
          <InvestigatorCard
            model={it}
            case_id = {this.props.model.getId()}
            updateModel={this.updateAllocatedInvestigator}
            removeModel={this.removeAllocatedInvestigator}
            enableReinvestigation={this.enableReinvestigation}
          />
        );
      });
    }

    return (
      <Segment loading={this.state.loading} basic>
        <TransitionablePortal open={this.state.evidenceSizeExceedsPopupVisible}>
          <Segment
            style={{
              left: "40%",
              position: "fixed",
              top: "50%",
              zIndex: 1000,
              backgroundColor: "#f08080",
              color: "#fff",
            }}
          >
            <Header style={{ color: "#fff" }}>Evidence Size Exceeds</Header>
            <p>{this.state.evidenceSizeExceedsMessage}</p>
            <p>Please delete some Evidences or Contact MD/CEO</p>
          </Segment>
        </TransitionablePortal>

        {this.props.model.getReinvestigation() && <div>
          <Header as="h3">
            Re-investigation Reports
          </Header>
          {/* report */}
          <Button
            as="a"
            href={report && report.getURL()}
            disabled={report === null}
            target="_blank"
            icon
            primary
            labelPosition="left"
            size="tiny"
          >
            <Icon name="file alternate outline" />
            View Report
          </Button>
          {/* report */}

          {/* repudiation evidence */}
          {model.getOutcome() == "Repudiation" && <Button
            icon
            primary
            labelPosition="left"
            size="tiny"
            disabled={
              this.state.repudiationEvidencePopupOpen ||
              repudiationEvidence.length === 0
            }
            onClick={() =>
              this.setState({ repudiationEvidencePopupOpen: true })
            }
          >
            <Icon name="file alternate outline" />
            {repudiationEvidence.length === 0
              ? "No Repudiation Evidence"
              : "View Repudiation Evidence"}
          </Button>}
          {/* repudiation evidence */}
          {/* <Label basic size={"large"} color={"blue"}>
            Case Type{" "}
            <Label.Detail>{model.getType().getTitle()}</Label.Detail>
          </Label>
          <Label basic size={"large"} color={"blue"}>
            Case Outcome <Label.Detail>{model.getOutcome()}</Label.Detail>
          </Label> */}
          {model.getRepudiationGround() && model.getOutcome() == "Repudiation" && <Label basic size={"large"} color={"blue"}>
            Repudation Ground <Label.Detail>{model.getRepudiationGround()}</Label.Detail>
          </Label>}
          {/* Final Report */}
          {
            filalReports?.getURL() !== null &&
            <Button
              as="a"
              href={filalReports && filalReports.getURL()}
              disabled={filalReports === null}
              target="_blank"
              icon
              primary
              labelPosition="left"
              size="tiny"
            >
              <Icon name="file alternate outline" />
              Final Report
            </Button>}
          {/* Repudiation Evidence */}
          {this.state.repudiationEvidencePopupOpen &&
            ReactDOM.createPortal(
              <FilesPopup
                title="Repudiation Evidence"
                onClose={() => this.setState({ repudiationEvidencePopupOpen: false })}
                files={repudiationEvidence} allocationId={model.getId()}           />,
              this.portalRoot!
            )}

          {/* Query triger Document */}
          {queryTriggerDocument?.getURL() !== null && model.getOutcome() == "Inconclusive" && <Button
            as="a"
            href={queryTriggerDocument && queryTriggerDocument.getURL()}
            disabled={queryTriggerDocument === null}
            target="_blank"
            icon
            primary
            labelPosition="left"
            size="tiny"
          >
            <Icon name="file alternate outline" />
            Query Trigger Document
          </Button>}
        </div>
        }
        <Header as="h3">
          Initial Closure
          <Header.Subheader>
            These fields needs to be filled by the Claim Analyst. Upon
            submitting case will be Quality Checked by the Operation Head and
            CEO/MD.
          </Header.Subheader>
        </Header>

        <Message info>
          NOTE: If 2 or more Claim Analysts are working on the case then case
          will only be eligible for Quality Check when all of them has submitted
          for the same.
        </Message>

        {allocatedInvestigators}
        {/* <Segment> */}

        <Header as="h3">Reports</Header>
        {this.state?.caseService && <CaseServiceTab />}
        {/* </Segment> */}
        <Segment>
          <Form>
            <Grid columns={3} verticalAlign="bottom">
              <Grid.Row>
                <Grid.Column>
                  <Form.Dropdown
                    label="Case Outcome"
                    search
                    selection
                    placeholder="Outcome"
                    options={this.toOutcomeOptions(
                      Object.values(constants.caseOutcome)
                    )}
                    value={this.state.caseOutcome}
                    onChange={(_, { value }) =>
                      this.setState({ caseOutcome: value as string })
                    }
                  />
                </Grid.Column>

                <Grid.Column>
                  <Form.Dropdown
                    label="Case Type"
                    search
                    selection
                    placeholder="Case Type"
                    loading={this.state.loadingCaseTypes}
                    value={this.state.caseType}
                    options={this.toCaseTypeOptions(this.state.caseTypes)}
                    onChange={(_, { value }) => {
                      if (
                        value !== this.props.model.getType().getId().toString()
                      ) {
                        this.setState((prev) => {
                          return {
                            changePopUp: !prev.changePopUp,
                            selectedCaseType: value,
                          };
                        });
                      } else {
                        this.setState((prev) => {
                          return {
                            selectedCaseType: value,
                            caseType: value,
                          };
                        });
                      }
                      if (this.state.confirmation == true) {
                        const caseType = this.getCaseTypeById(value as string);
                        if (caseType === null) return;
                        this.setState({
                          caseType: value as string,
                          caseTypeModel: caseType,
                          // changePopUp: true
                        });
                      }
                    }}
                  // onClick={() => this.setState({ changePopUp: true })}
                  />
                </Grid.Column>

                <Grid.Column>
                  <Form.Dropdown
                    label="Repudiation Ground"
                    search
                    selection
                    placeholder="Repudiation Ground"
                    value={this.state.repudiation_Ground}
                    options={RepudiationGround}
                    onChange={(_, { value }) =>
                      this.setState({ repudiation_Ground: value as string })
                    }
                    disabled={
                      this.state.caseOutcome !==
                      constants.caseOutcome.repudiation
                    }
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Form.Input
                    label="Report"
                    type="file"
                    accept="image/*, .pdf, .doc, .docx, .csv, .xls, .xlsx"
                    onChange={(e) => {
                      const files = e.target.files;
                      if (files === null) this.setState({ report: null });
                      else this.setState({ report: files[0] });
                    }}
                  />
                </Grid.Column>

                <Grid.Column>
                  <Form.Input
                    label="Query Trigger Document"
                    type="file"
                    accept="image/*, .pdf, .doc, .docx, .csv, .xls, .xlsx"
                    onChange={(e) => {
                      const files = e.target.files;
                      if (files === null)
                        this.setState({ Query_Trigger_Document: null });
                      else this.setState({ Query_Trigger_Document: files[0] });
                    }}
                    disabled={
                      this.state.caseOutcome !==
                      constants.caseOutcome.inconclusive
                    }
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input
                    label="Repudiation Evidence"
                    disabled={
                      this.state.caseOutcome !==
                      constants.caseOutcome.repudiation
                    }
                    type="file"
                    multiple
                    accept="image/*, .pdf, .doc, .docx, .csv, .xls, .xlsx"
                    onChange={(e) => {
                      const files = e.target.files;
                      if (files === null) {
                        this.setState({ repudiationEvidence: [] });
                        return;
                      }
                      let a = [];
                      for (let i = 0; i < files.length; i++) a.push(files[i]);
                      this.setState({ repudiationEvidence: a });
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Button
                    positive
                    disabled={
                      this.state.form_investigators.findIndex((it) =>
                        it.getActive()
                      ) !== -1 ||
                      this.state.caseOutcome.trim().length === 0 ||
                      this.state.caseType.trim().length === 0 ||
                      this.state.report === null ||
                      (this.state.caseOutcome ===
                        constants.caseOutcome.repudiation &&
                        (this.state.repudiationEvidence.length === 0 ||
                          this.state.repudiation_Ground.length === 0)) ||
                      (this.state.caseOutcome ===
                        constants.caseOutcome.inconclusive &&
                        this.state.Query_Trigger_Document === null)
                    }
                    onClick={this.onSubmit}
                  >
                    Close (Initial Closure)
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Segment>

        <Modal
          size="tiny"
          open={this.state.changePopUp}
          onClose={() => this.setState({ changePopUp: false })}
          closeIcon
          closeOnDimmerClick={false}
          closeOnEscape={false}
        >
          <Modal.Header>Case Type Change Confirmation.</Modal.Header>
          <Modal.Content>
            <div>
              {/* {console.log(this.state.selectedCaseType, this.props.model.getType().getId(), "vvvvvvvvvvvvvv")} */}
              <br />
              <p>Do you want to change the case type?</p>
              <Button
                positive
                onClick={() =>
                  this.setState((prev) => {
                    return {
                      confirmation: !prev.confirmation,
                      changePopUp: !prev.changePopUp,
                      caseType: this.state.selectedCaseType,
                      newCaseCategoryCode:
                        this.state.caseTypeModel.getCaseCategoryCode(),
                      closeCaseTypeModel:
                        this.shouldRenderCaseOptionalFieldsInput(
                          this.state.selectedCaseType
                          //prev.caseTypeModel
                          //false
                        ),
                    };
                  })
                }
              >
                yes
              </Button>
              <Button
                negative
                onClick={() => this.setState({ changePopUp: false })}
              >
                No
              </Button>
            </div>
          </Modal.Content>
        </Modal>

        <Modal
          size="tiny"
          open={this.state.closeCaseTypeModel}
          closeIcon
          onClose={() =>
            this.setState((prev) => {
              return {
                closeCaseTypeModel: !prev.closeCaseTypeModel,
              };
            })
          }
          closeOnDimmerClick={false}
          closeOnEscape={false}
        >
          <Modal.Header>Case Required Fields</Modal.Header>
          <Modal.Content>
            <CaseOptionalFieldsInputForm
              model={this.props.model}
              caseType={this.state.caseTypeModel}
              onSuccess={this.props.updateModel}
            />
          </Modal.Content>
        </Modal>
      </Segment>
    );
  }

  /**
   * Converts CaseType[] to DropdownItemProps[]
   *
   * @param {CaseType[]} caseTypes
   * @returns {DropdownItemProps[]}
   */
  private toCaseTypeOptions = (caseTypes: CaseType[]) => {
    const options: DropdownItemProps[] = caseTypes.map((it) => {
      return { key: it.getId(), value: it.getId(), text: it.getTitle() };
    });
    return options;
  };

  /**
   * Converts string[] to DropdownItemProps[]
   *
   * @param {string[]} outcomeArray
   * @returns {DropdownItemProps[]}
   */
  private toOutcomeOptions = (outcomeArray: string[]) => {
    const options: DropdownItemProps[] = outcomeArray.map((it) => {
      return { key: it, value: it, text: it };
    });
    return options;
  };

  /**
   * Check whether case's field input form should render
   * or not on case type change
   *
   * @param {CaseType} newCaseType
   * @returns {boolean}
   */
  private shouldRenderCaseOptionalFieldsInput = (castetypeid: string) => {
    let caseTypeObj = this.state.caseTypes.find(
      (o) => o.getId().toString() == castetypeid.toString()
    );

    let model = this.props.model;

    if (this.state.newCaseCategoryCode == caseTypeObj!.getCaseCategoryCode()) {
      this.setState({
        newCaseCategoryCode: caseTypeObj!.getCaseCategoryCode(),
      });
      return false;
    }
    if (
      model.getInsuredLocation() === null ||
      model.getInsuredAddress() === null ||
      model.getInsuredContact() === null
    ) {
      this.setState({
        newCaseCategoryCode: caseTypeObj!.getCaseCategoryCode(),
      });

      return true;
    }
    this.setState({
      newCaseCategoryCode: caseTypeObj!.getCaseCategoryCode(),
    });

    return false;
  };

  /**
   * Find CaseType by id
   *
   * @param {string} caseTypeId
   * @return {CaseType | null}
   */
  private getCaseTypeById = (caseTypeId: string) => {
    const index = this.state.caseTypes.findIndex(
      (it) => it.getId() === caseTypeId
    );
    if (index === -1) return null;
    return this.state.caseTypes[index];
  };
}

const InvestigatorsLoadingState = () => {
  return (
    <React.Fragment>
      <Segment>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Placeholder>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder>
            </Grid.Column>

            <Grid.Column>
              <Placeholder>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      <Segment>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Placeholder>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder>
            </Grid.Column>

            <Grid.Column>
              <Placeholder>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </React.Fragment>
  );
};

export default (props: any) => {
  return (
    <RequirePermission permission={permissions.Case.fold}>
      <ActionFold {...props} />
    </RequirePermission>
  );
};