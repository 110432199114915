import React from 'react'
import Client from '../../../../models/Client'

interface ClientListingPageContextType {
  loading: boolean
  setLoading(loading: boolean): void

  clients: Client[]
  setClients(clients: Client[]): void

  filter: string
  setFilter(filter: string): void

  limit: number
  setLimit(limit: number): void

  offset: number
  setOffset(offset: number): void

  count: number
  setCount(count: number): void

  load(): void
  remove(client: Client, onRemoval?: () => void): void
  disable(client: Client, onDisable?:() => void): void
}

const initialValue: ClientListingPageContextType = {
  loading: false,
  setLoading: () => {},
  clients: [],
  setClients: () => {},
  filter: '',
  setFilter: () => {},
  limit: 0,
  setLimit: () => {},
  offset: 0,
  setOffset: () => {},
  count: 0,
  setCount: () => {},
  load: () => {},
  remove: () => {},
  disable: () => {}
}

export default React.createContext<ClientListingPageContextType>(initialValue)
