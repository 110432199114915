
export default {
  apiBaseURLSocket: 'http://amableclaimprobe.com/',
  mrdRecipt: 'http://amableclaimprobe.com/',
  apiBaseURL: "http://amableclaimprobe.com/api",
}

// export default {
//    apiBaseURLSocket: "http://192.168.0.104:3111",
//    mrdRecipt: 'http://192.168.12.70:3014/',
//    apiBaseURL: "http://192.168.0.104:3111/api" ,
// }