import React, { useEffect, useState } from 'react';
import { Button, Modal, Table } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';
import FileLabel from '../../common/FileLabel';
import Result from '../../../common/repository/Result';
import config from '../../../common/repository/config';
import InvoiceSectionRepository from '../../../common/repository/InvoiceSectionRepository';
import { toast } from 'react-toastify';
import InvoiceSection, { InvoiceSection_Invoice } from '../../../models/InvoiceSection';
import InvoiceUpdateForm from '../../page/billing/InvoiceSectionPage/InvoiceUpdateForm';
import LocalStorage from '../../../lib/LocalStorage';
import NotificationPopup from './NotificationPopUp';
import constants from '../../../common/constants';
import { useSelector, useDispatch } from "react-redux";
import { AppState } from '../../../redux';

interface Notification {
  type_id: string;
  title: string;
  message: string;
  type: string
  metadata: {
    claim_number: string;
    document_expense?: number;
    approved_expense?: number;
    extra_expense?: number;
    case_type?: string;
    case_id: string;
    document_expense_receipt?: string[];
    approved_expense_receipt?: string[];
    extra_expense_receipt?: string[];
  };
}

interface updateInvoiceData {
  case_id: string,
  approved_expense?: number,
  document_expense?: number,
  extra_expense?: number,
  case_type_title?: string
}

const InvoiceNotificationList: React.FC = () => {
  const invoiceRepo = new InvoiceSectionRepository();
  const [modal, updateModal] = useState<InvoiceSection[]>([]);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [missedNotifications, setMissedNotifications] = useState<any[]>([]);
  const [missedInvoice, setMissedInvoice] = useState<any[]>([]);
  const [notificationError, setNotificationError] = useState<string | null>(null);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [invoiceNotificationData, setInvoiceNotificationData] = useState<any | null>(null);
  const authState = useSelector((state: AppState) => state.auth);
  const user = authState.user;

  const location = useLocation();
  const notifications: Notification[] = location.state?.notificationData ? [location.state.notificationData] : [];
  const getLatestReceipt = (document_expense_receipt: string[] | string, extra_expense_receipt: string[] | string, approved_expense_receipt: string[] | string): string[] => {
    if (
      (typeof document_expense_receipt === 'string' && document_expense_receipt === "") &&
      (typeof extra_expense_receipt === 'string' && extra_expense_receipt === "") &&
      (typeof approved_expense_receipt === 'string' && approved_expense_receipt === "")
    ) {
      return [];
    }
    return [
      document_expense_receipt![document_expense_receipt.length - 1],
      extra_expense_receipt![extra_expense_receipt.length - 1],
      approved_expense_receipt![approved_expense_receipt.length - 1]
    ]
  };

  const latestReceiptData = notifications.map((notification) => ({
    ...notification,
    latestReceipt: getLatestReceipt(notification.metadata.document_expense_receipt || "", notification.metadata.extra_expense_receipt || "", notification.metadata.approved_expense_receipt || "")
  }));
  const renderReceiptList = (evidence: string | string[] | undefined) => {
    if (!evidence || (Array.isArray(evidence) && evidence.length === 0) || (typeof evidence === 'string' && evidence.trim().length === 0)) {
      return "No receipt uploaded yet";
    }
    const evidenceArray = Array.isArray(evidence) ? evidence : [evidence];
    const receipt = evidenceArray[evidenceArray.length - 1];
    if (!receipt) {
      return "Invalid receipt data";
    }
    const receiptArray = receipt.split("/");
    const receiptName = receiptArray.pop();
    const envurl = config.apiBaseURL.slice(0, -3);
    const url = envurl + receipt;
    return (
      <FileLabel.Group horizontal>
        <FileLabel title={receiptName || ''} link={url} />
      </FileLabel.Group>
    );
  };
  const openInvoice = async (invoiceUpdateData: updateInvoiceData) => {
    const response = await invoiceRepo.getData({
      case_id: invoiceUpdateData.case_id
    });
    if (response instanceof Result.Success) {
      const invoiceData = response.data.items[0];
      const invoice = new InvoiceSection_Invoice(
        invoiceData.getInvoice()?.getInvoiceNumber() || "",
        invoiceData.getInvoice()?.getInvoiceDate() || "",
        invoiceData.getInvoice()?.getProfessionalFee() || 0,
        invoiceUpdateData.document_expense ?? invoiceData.getInvoice()?.getDocumentExpense() ?? 0,
        invoiceUpdateData.approved_expense ?? invoiceData.getInvoice()?.getApprovedExpense() ?? 0,
        invoiceUpdateData.extra_expense ?? invoiceData.getInvoice()?.getExtraExpense() ?? 0,
        invoiceData.getInvoice()?.getIncentive() || 0,
        invoiceData.getInvoice()?.getDeduction() || 0,
        invoiceData.getInvoice()?.getGst() || 0,
        invoiceData.getInvoice()?.getInvoiceUrl() || null,
      );

      const data: InvoiceSection[] = [
        new InvoiceSection(
          invoiceData.getId(),
          invoiceData.getCaseId(),
          invoiceData.getClaimNumber(),
          invoiceData.getClientId(),
          invoiceData.getClientName(),
          invoiceData.getCaseTypeId(),
          invoiceUpdateData.case_type_title ? invoiceUpdateData.case_type_title : invoiceData.getCaseTypeTitle(),
          invoiceData.getCloseDate(),
          invoiceData.getOldDocCharges(),
          invoiceData.getOldApprovedCharges(),
          invoiceData.getOldExtraCharges(),
          invoice
        )
      ];
      updateModal(data);
      setUpdateModalOpen(true);
    } else {
      toast.error(response.message);
    }
  };
  const onInvoiceSectionUpdated = async () => {
    setUpdateModalOpen(false);
    updateModal([]);
  };

  // useEffect(() => {
  //   return  () => {
  //       const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
  //       if (!token) {
  //         console.error('Authentication token is missing.');
  //         return;
  //       }
  //       fetch(`${config.apiBaseURL}/notification/missedInvoiceChanges`, {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'x-access-token': token,
  //         },
  //       }).then(response => {
  //         if (!response.ok) {
  //           throw new Error('Network response was not ok');
  //         }
  //         return response.json();
  //       })
  //       .then(data => {
  //         console.log('missedData: ', data);
  //         const notificationData = {
  //           typeId: data.data.items[0].type_id || '',
  //           history_id: data.data.items[0].history_id || '',
  //           metadata: data.data.items[0].metadata || {

  //           },
  //           message: data.data.items[0].message || '',
  //           title: data.data.items[0].title || '',
  //           type_id: data.data.items[0].type_id || '',
  //         };
  //         setMissedNotifications(prev => [...prev, notificationData]);
  //         setInvoiceNotificationData(notificationData);
  //         setShowPopup(true);
  //         toast.error("sdffds")
  //         setMissedInvoice(data.data.items || []);
  //       })
  //       .catch(error => {
  //         setNotificationError(error instanceof Error ? error.message : 'An unknown error occurred');
  //       });
  //   };
  // },[]);
  return (
    <>
      {/* <div className="notification-popups">
        {user?.getRole().getTitle() === constants.roleTitle.ao && (
          missedNotifications.length > 0 ? (
            missedNotifications.map((notification, index) => (
              notification && notification.title ? (
                <NotificationPopup
                  key={`missed-${index}`}
                  notificationData={notification}
                />
              ) : null
            ))
          ) 
          : null
          // (
          //   notifications.map((notification, index) => (
          //     notification && notification.title ? (
          //       <NotificationPopup
          //         key={`notif-${index}`}
          //         notificationData={notification}
          //       />
          //     ) : null
          //   ))
          // )
        )}
      </div> */}
      <Table celled style={{ width: '100%', textAlign: 'center' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Message</Table.HeaderCell>
            <Table.HeaderCell>Claim Number</Table.HeaderCell>
            {
              latestReceiptData[0]?.latestReceipt.length > 0 ?
                (
                  <>
                    <Table.HeaderCell>Document Expense</Table.HeaderCell>
                    <Table.HeaderCell>Approved Expense</Table.HeaderCell>
                    <Table.HeaderCell>Extra Expense</Table.HeaderCell>
                    <Table.HeaderCell>Approved Expense Receipt</Table.HeaderCell>
                    <Table.HeaderCell>Extra Expense Receipt</Table.HeaderCell>
                    <Table.HeaderCell>Document Expense Receipt</Table.HeaderCell>
                  </>
                )
                :
                (
                  <Table.HeaderCell>Case Type</Table.HeaderCell>
                )
            }
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {latestReceiptData.map((notification) => (
            <Table.Row key={notification.type_id}>
              <Table.Cell>{notification.title}</Table.Cell>
              <Table.Cell>{notification.message}</Table.Cell>
              <Table.Cell>{notification.metadata.claim_number}</Table.Cell>
              {
                latestReceiptData[0]?.latestReceipt.length > 0 ?
                  (
                    <>
                      <Table.Cell>{notification.metadata.document_expense}</Table.Cell>
                      <Table.Cell>{notification.metadata.approved_expense}</Table.Cell>
                      <Table.Cell>{notification.metadata.extra_expense}</Table.Cell>
                      <Table.Cell>{renderReceiptList(notification.metadata.approved_expense_receipt)}</Table.Cell>
                      <Table.Cell>{renderReceiptList(notification.metadata.extra_expense_receipt)}</Table.Cell>
                      <Table.Cell>{renderReceiptList(notification.metadata.document_expense_receipt)}</Table.Cell>
                    </>
                  )
                  :
                  (
                    <Table.Cell>{notification.metadata.case_type}</Table.Cell>
                  )
              }
              <Table.Cell>
                <Button
                  style={{
                    backgroundColor: "blue",
                    color: "white",
                    padding: "10px 20px",
                    borderRadius: "8px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    cursor: "pointer",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    transition: "background-color 0.3s ease"
                  }}
                  onClick={() => openInvoice(latestReceiptData[0].latestReceipt.length > 0 ?
                    {
                      case_id: latestReceiptData[0].metadata.case_id,
                      approved_expense: latestReceiptData[0].metadata.approved_expense,
                      document_expense: latestReceiptData[0].metadata.document_expense,
                      extra_expense: latestReceiptData[0].metadata.extra_expense,
                      case_type_title: latestReceiptData[0].metadata.case_type
                    }
                    :
                    {
                      case_id: latestReceiptData[0].metadata.case_id,
                      case_type_title: latestReceiptData[0].metadata.case_type
                    })}
                >
                  Open
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>

      </Table>

      <Modal
        open={updateModalOpen}
        onClose={() => setUpdateModalOpen(false)}
        size="tiny"
        closeIcon
        closeOnDimmerClick={false}
        closeOnEscape={false}
      >
        <Modal.Header>Update Information</Modal.Header>
        <Modal.Content>
          <InvoiceUpdateForm
            model={modal[0] || undefined}
            onSuccess={onInvoiceSectionUpdated}
          />
        </Modal.Content>
      </Modal>
    </>
  );
};

export default InvoiceNotificationList;
