import React, { useState, useEffect } from "react";
import CompleteCase from "../../../../../models/CompleteCase";
import styled from "styled-components";
import CaseRepository from "../../../../../common/repository/CaseRepository";
import CaseHistory from "../../../../../models/CaseHistory";
import Result from "../../../../../common/repository/Result";
import { toast } from "../../../../common/Toast";
import { Header, Icon, Table, Button } from "semantic-ui-react";
import parser from "./parser";
import moment from "moment";
import { Link } from "react-router-dom";
import LocalStorage from "../../../../../lib/LocalStorage";
import config from "../../../../../common/repository/config";

const Wrapper = styled.div<{ width: string }>`
  width: ${(p) => p.width};
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: #eee;
  transition: all 0.33s ease;
`;

const Item = styled.div`
  padding: 4px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 4px;
  overflow-wrap: break-word;
  overflow: auto;
`;

const ItemTable = styled(Table as any)`
  margin-top: 20px;
`;

const HistoryTable = styled(Table as any)`
  margin-top: 10px;
`;

interface HistoryProps {
  model: CompleteCase;
}

function History({ model }: HistoryProps) {
  const repo = new CaseRepository();
  const [history, setHistory] = useState<CaseHistory[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    effectLoadHistory();
    // loadNewData();  // Assuming you want to load new data on component mount as well
  }, [model]);
  const caseId  = model.getId();
  console.log(caseId,"{{{")

  // Fetch new data from the API
  // const loadNewData = async () => {
  //   try {
  //     const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
  //     if (!token) {
  //       setToastMessage('Authentication token is missing.');
  //       setToastType('error');
  //       return;
  //     }
  //     const response = await fetch(`${config.apiBaseURL}/case/listMrdHistory/${caseId}`,{
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'x-access-token': token,
  //       },
  //     });
  //     const data = await response.json();
  //     if (data.success) {
  //       setItems(data.data.items);
  //     } else {
  //       throw new Error(data.message || "Failed to fetch items");
  //     }
  //   } catch (error) {
  //     // toast.error(error.message || "An error occurred");
  //   }
  // };

  // Fetch history data from the repository
  async function effectLoadHistory() {
    const result = await repo.getCaseHistory(model.getId());
    if (result instanceof Result.Success) {
      setHistory(result.data.items);
    } else {
      const message = result.message || "Something went wrong";
      toast.error(message);
    }
  }

  const historyElements = history.map((it) => {
    const parsed = parser(it);
    return (
      <Item>  {/* Added a key prop */}
        <strong>State: {parsed.getCaseState()}</strong>
        <strong>{parsed.getUser()}</strong>
        <span>{moment(parsed.getTimestamp()).format("LLL")}</span>
        {parsed.getSummary().length > 0 ? (
          <Table style={{ fontSize: 12 }} compact size="small" fixed>
            {parsed.getSummary().map((it, index) => (  // Added key prop
              <Table.Row key={index}>  {/* Added a key prop */}
                <Table.Cell>{it.key}</Table.Cell>
                <Table.Cell>{it.value}</Table.Cell>
              </Table.Row>
            ))}
          </Table>
        ) : null}
      </Item>
    );
  });

  return (
    <>
      <Wrapper width={isExpanded ? "480px" : "60px"}>
        <Icon
          name="expand"
          size="big"
          onClick={() => setIsExpanded(!isExpanded)}
        />
        {isExpanded && (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Header as="h3">Case History</Header>
              <div title="Case Investigator History">
                <Button
                  size='tiny'
                  style={{ width: 110, marginBottom: 10 }}
                  primary
                  as={Link}
                  to={{
                    pathname: `/case/case-investigators-history/${model.getId()}`,
                  }}
                >
                  Case Inve His
                </Button>
              </div>
            </div>
            {historyElements}
            <>
            <ItemTable celled structured>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Case ID</Table.HeaderCell>
                <Table.HeaderCell>Created On</Table.HeaderCell>
                <Table.HeaderCell>Amount</Table.HeaderCell>
              
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {items.length > 0 ? (
                items.map((item) => (
                  <Table.Row key={item.case_id}>
                    <Table.Cell>{item.case_id}</Table.Cell>
                    <Table.Cell>{moment(item.created_on).format("LLL")}</Table.Cell>
                    <Table.Cell>
                      {item.history.map((h: { amount: any; }) => h.amount).join(', ')}
                    </Table.Cell>
              
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={4} style={{textAlign:"center"}}>No Ammount Apporve history available for this case.</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </ItemTable>

            {items.map((item) => (
            item.history.map((historyItem:any) => (
              <HistoryTable celled structured key={historyItem._id}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Amount</Table.HeaderCell>
                    <Table.HeaderCell>State</Table.HeaderCell>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell>By</Table.HeaderCell>
                    <Table.HeaderCell>Reason</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {historyItem.state_history.map((state:any) => (
                    <Table.Row key={state._id}>
                      <Table.Cell>{historyItem.amount}</Table.Cell>
                      <Table.Cell>{state.state}</Table.Cell>
                      <Table.Cell>{moment(state.date).format("LLL")}</Table.Cell>
                      <Table.Cell>{state.by.name} ({state.by.role_name})</Table.Cell>
                      <Table.Cell>{state.reason || '-'}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </HistoryTable>
            ))
          ))}
          </>
          </>
        )}
      </Wrapper>

      <Wrapper width={isExpanded ? "480px" : "60px"}>
        
      
      </Wrapper>
    </>
  );
}

export default History;
function setToastMessage(arg0: string) {
  throw new Error("Function not implemented.");
}

function setToastType(arg0: string) {
  throw new Error("Function not implemented.");
}

