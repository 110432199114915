import React, { JSXElementConstructor, ReactElement, ReactNodeArray, ReactPortal } from "react";
import { Segment, List, DropdownItemProps, Image, Grid, Divider, Header, Icon, Button, Form, Accordion, Input, Modal, Table, Dropdown, DropdownProps } from "semantic-ui-react";
import UserRepository from "../../../../../common/repository/UserRepository";
import Result from "../../../../../common/repository/Result";
import User from "../../../../../models/User";
import States from "../../../../../models/States";
import InvestigatorProfileCard from "../InvestigatorProfileCard";
import CaseRepository from "../../../../../common/repository/CaseRepository";
import StateDistrictRepository from "../../../../../common/repository/StatesDistrictsRepository";
import Case from "../../../../../models/Case";
import { toast } from "../../../../common/Toast";
import constants from "../../../../../common/constants";
import RequirePermission from "../../../../base/RequirePermission";
import permissions from "../../../../../common/permissions";
import { TagsInput } from "react-tag-input-component";
import config from "../../../../../common/repository/config";
// import CallPage from "./CallingPage";
// import axios from "axios";
import LocalStorage from "../../../../../lib/LocalStorage";
import VirtualKeypad from "../../../../VirtualKeypad/VirtualKeypads";
import { BooleanLiteral } from "typescript";
const investigationSpecifications = ["Cashless", "Detailed Investigation", "Insured Part", "Hospital Part", "Vicinity Check", "Document Verification",];

interface ActionAllocationProps {
  model: Case;
  updateModel(c: Case): void;
}

interface UserWithStyle extends User {
  style?: string;
}

interface ActionAllocationState {
  roles: { id: string; title: string }[];
  role: string;
  inputValue: string;
  keypadVisible: boolean;
  modalOpen: boolean;
  rolesInCase: boolean;
  selectedRoleInCase: string | null;
  apiResponseModalOpen: any,
  apiResponseData: any,
  callItemModalOpen: boolean;
  callItemNumber: string;
  name: string;
  userRole: string;
  stakeholder_role: { key: string, text: string, value: string }[]
  options: { key: string; text: string; value: string }[];
  contactNumber: string;
  handleConfirmCall: string;
  isCalling: boolean;
  isMinimized: boolean;
  confirmModalOpen: boolean;
  selectedContactNumber: string | null;
  active: boolean;
  loading: boolean;
  loadingInvestigators: boolean;
  investigatorOptions: DropdownItemProps[];
  investigators: User[];
  loadingStates: boolean;
  stateOptions: DropdownItemProps[];
  states: States[];
  selected_state: string;
  loadingDistrict: boolean;
  districtOptions: DropdownItemProps[];
  district: string[];
  selected_district: string;
  perference: any;
  alloc_selectedInvestigator: string; //id
  alloc_selectedInvestigationSpecifications: string[];
  alloc_otherInvestigationSpecifications: string;
  alloc_selection: { investigator: User; specs: string[]; serviceType: string[], required_doc: string[] }[];
  servicesType: any[]
  services: string[]
  required_documents: string[]
  responseMessage: string | null;
  invoiceUrl: string;
}
class ActionAllocation extends React.Component<
  ActionAllocationProps,
  ActionAllocationState
> {
  private usersRepo = new UserRepository();
  private caseRepo = new CaseRepository();
  private stateDistrictRepo = new StateDistrictRepository();

  constructor(props: ActionAllocationProps) {
    super(props);
    this.state = {
      options: [],
      inputValue: '',
      keypadVisible: false,
      rolesInCase: false,
      selectedRoleInCase: null,
      callItemNumber: "",
      apiResponseModalOpen: false,
      callItemModalOpen: false,
      apiResponseData: null,
      isCalling: false,
      isMinimized: false,
      active: false,
      loading: false,
      stakeholder_role: [],
      loadingInvestigators: false,
      investigatorOptions: [],
      investigators: [],
      loadingStates: false,
      stateOptions: [],
      states: [],
      selected_state: "",
      loadingDistrict: false,
      districtOptions: [],
      district: [],
      selected_district: "",
      perference: null,
      alloc_selectedInvestigator: "",
      alloc_selectedInvestigationSpecifications: [],
      alloc_otherInvestigationSpecifications: "",
      alloc_selection: [],
      servicesType: props.model.getType().getServices(),
      services: props.model.getType().getServices(),
      required_documents: props.model.getType().getRequiredDocumne(),
      modalOpen: false,
      name: "",
      userRole: "",
      role: "",
      roles: [],
      contactNumber: "",
      confirmModalOpen: false,
      selectedContactNumber: null,
      handleConfirmCall: "",
      responseMessage: "",
      invoiceUrl: "",

    };
  }
  componentDidMount() {
    this.getInvestigators();
    this.getState();
    this.getUser();
  }

  private getUser = async () => {
    let result = await this.usersRepo.getUserByToken();
    if (result instanceof Result.Success) {
      this.setState({ userRole: result.data.getRole().getTitle() });
    }
  }

  private getState = () => {
    this.setState({ loadingStates: true }, async () => {
      let result = await this.stateDistrictRepo.getStates();
      if (result instanceof Result.Success) {
        const fetchedStates = result.data.items;
        const options = fetchedStates.map((it: any) => {
          return this.stateToDropDownOption(it);
        });

        this.setState({
          loadingStates: false,
          stateOptions: options.sort((a: any, b: any) => {
            if (a.text < b.text) return -1;
            if (a.text > b.text) return 1;
            return 0;
          }),
          states: fetchedStates
        });
      } else {
        this.setState({ loadingStates: false }, () => {
          const message = "Could not load States";
          toast.error(message);
        });
      }
    });
  };

  private getDistrict = (stateName: string) => {
    this.setState({ loadingDistrict: true }, async () => {
      let result = await this.stateDistrictRepo.getDistricts({ state: stateName });
      if (result instanceof Result.Success) {
        const fetchedDistricts = result.data.items;

        const options = fetchedDistricts.map((it: string) => {
          return this.districtToDropDownOption(it);
        });

        this.setState({
          loadingDistrict: false,
          districtOptions: options.sort((a: any, b: any) => {
            if (a.text < b.text) return -1;
            if (a.text > b.text) return 1;
            return 0;
          }),
          district: fetchedDistricts
        });

      } else {
        this.setState({ loadingDistrict: false }, () => {
          const message = "Could not load Districts";
          toast.error(message);
        });
      }
    });
  };

  private getInvestigators = (state?: string, district?: string) => {
    this.setState({ loadingInvestigators: true }, async () => {
      let result: any;
      if (state && district !== undefined) {
        result = await this.usersRepo.getUsersByRoleTitle({
          role: constants.roleTitle.investigator,
          location: {
            state: state,
            district: district,
          }
          // is_active: true,
        });
      } else {
        result = await this.usersRepo.getUsersByRoleTitle({
          role: constants.roleTitle.investigator
          // is_active: true,
        });
      }

      if (result instanceof Result.Success) {
        const investigators = result.data.items;
        const options = investigators.map((it: any) => {
          return this.investigatorToDropDownOption(it);
        });
        this.setState({
          loadingInvestigators: false,
          investigatorOptions: options,
          investigators,
        });
      } else {
        this.setState({ loadingInvestigators: false }, () => {
          const message = result.message || "Could not load Investigators";
          toast.error(message);
        });
      }
    });
  };
  private allocateInvesitgators = () => {
    this.setState({ loading: true }, async () => {
      const { model, updateModel } = this.props;
      const allocations = this.state.alloc_selection.map((it) => {
        return {
          investigator_id: it.investigator.getId(),
          specifications: it.specs,
          services: it.serviceType,
          required_documents: it.required_doc
        };
      });
      const result = await this.caseRepo.allocateInvestigators({
        case_id: model.getId(),
        allocations,
      });
      if (result instanceof Result.Success) {
        const c = result.data;
        this.setState(
          {
            loading: false,
            investigatorOptions: [],
            investigators: [],
            alloc_selectedInvestigator: "",
            selected_state: "",
            selected_district: "",
            alloc_selectedInvestigationSpecifications: [],
            alloc_otherInvestigationSpecifications: "",
            alloc_selection: [],
          },
          () => {
            updateModel(c);
            toast.success("Case Allocated Successfully");
            this.getInvestigators();
          }
        );
      } else {
        const message = result.message || "Something went wrong";
        this.setState({ loading: false }, () => {
          toast.error(message);
        });
      }
    });
  };
  handleOpenConfirmModal = (contactNumber: string) => {
    this.setState({ confirmModalOpen: true, selectedContactNumber: contactNumber });
  };
  handleCloseConfirmModal = () => {
    this.setState({ confirmModalOpen: false, selectedContactNumber: null });
  };

  handleEndCall = () => {
    this.setState({ isCalling: false, confirmModalOpen: false });
  };
  toggleMinimizeModal = () => {
    this.setState((prevState) => ({
      isMinimized: !prevState.isMinimized,
    }));
  };
  handleService = (e: any) => {
    const { value, checked } = e.target
    if (checked) {
      this.setState({ services: [...this.state.services, value] })
    } else {
      this.setState({ services: this.state.services.filter((e) => e !== value) })
    }
  }
  handleCheckboxChange = (event: any) => {
    let newArray = [...this.state.services, event.target.id];
    if (this.state.services.includes(event.target.id)) {
      newArray = newArray.filter(day => day !== event.target.id);
    }
    this.setState({
      services: newArray
    });
  };
  fetchInvoiceDocument = async (case_id: any) => {
    try {
      const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
      if (!token) {
        console.error('Authentication token is missing.');
        return;
      }

      const response = await fetch(`${config.apiBaseURL}/case/listInvoiceQc/${case_id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'x-access-token': token,
        },
      });

      const data = await response.json();
      console.log(data, "{{[");

      if (data.success) {
        const { total_item_count, items, message } = data.data;

        if (total_item_count > 0 || (typeof items === 'string' && items.trim() !== '')) {
          this.setState({ responseMessage: null, invoiceUrl: items });
        } else {
          this.setState({ responseMessage: message });
          toast.error(message);
        }
      } else {
        this.setState({ responseMessage: data.message });
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error fetching invoice QC list:", error);
      this.setState({ responseMessage: "Error occurred while fetching data" });
      // toast.error("Error occurred while fetching data");
    }
  };

  handleOpenDocument = () => {
    const { invoiceUrl } = this.state;
    if (invoiceUrl) {
      console.log(invoiceUrl,"InvoiceUrl");
      window.open(invoiceUrl, '_blank');
    } else {
      toast.error("Invoice document not available.");
    }
  };


  render() {
    const { isCalling, isMinimized, apiResponseModalOpen, apiResponseData, callItemModalOpen, callItemNumber, roles, role, options, inputValue, keypadVisible } = this.state;
    if (this.state.isCalling) {
      return (
        <div>
          <Modal
            open={callItemModalOpen}
            onClose={() => this.setState({ callItemModalOpen: false })}
            size="small"
          >
            <Modal.Actions>
              <Button negative onClick={() => this.setState({ callItemModalOpen: false })}>
                Cancel
              </Button>
              <Button positive
              //  onClick={this.handleCallItemNumberSubmit}
              >
                Submit
              </Button>
            </Modal.Actions>
          </Modal>
          {isCalling && (
            <div
              style={{
                position: 'fixed',
                bottom: isMinimized ? 0 : '2%',
                right: '10%',
                zIndex: 1000,
                width: isMinimized ? '500px' : '400px',
              }}
            >
              <Segment raised>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {isMinimized ? (
                    <Icon
                      name="window restore"
                      onClick={this.toggleMinimizeModal}
                      style={{ cursor: 'pointer' }}
                    />
                  ) : (
                    <>
                      <span>Calling Contact {this.state.selectedContactNumber} </span>
                      <Icon
                        name="window minimize"
                        onClick={this.toggleMinimizeModal}
                        style={{ cursor: 'pointer' }}
                      />
                    </>
                  )}
                </div>
                {!isMinimized && (
                  <>
                    <Segment >
                      <Icon name="phone" loading />
                      Calling contact...{this.state.selectedContactNumber}
                    </Segment>
                    <Button
                      basic
                      color="red"
                      onClick={this.handleEndCall}
                      style={{ marginTop: '10px' }}
                    >
                      End Call
                    </Button>
                  </>
                )}
              </Segment>
            </div>
          )}
        </div>
      );
    }
    const selectionList = this.state.alloc_selection.map((it) => {
      return (
        <InvestigatorProfileCard
          showHeader
          model={it.investigator}
          investigationSpecifications={it.specs}
          required_doc={it.required_doc}
          serviceType={it.serviceType}
          onRemove={this.onRemove}
        />
      );
    });
    const investigationSpecsOptions = investigationSpecifications.map((it) => {
      return { text: it, value: it, key: it };
    });
    const caseState = this.props.model.getState();


    return (
      <>
        {this.props.model.getState() === "open" && (

          <Segment>
            {/* <Header as="h4">Added Contacts</Header>
<Table celled>
  <Table.Header>
    <Table.Row >
      <Table.HeaderCell>Name</Table.HeaderCell>
      <Table.HeaderCell>Role In Case</Table.HeaderCell>
      <Table.HeaderCell>Contact Number</Table.HeaderCell>
    </Table.Row>
  </Table.Header>
  <Table.Body>
    {this.state.contact.length ? (
      this.state.contact
        .slice()
        .reverse()
        .map((contact, index) => (
          <Table.Row key={index}>
            <Table.Cell>{contact.name}</Table.Cell>
            <Table.Cell>{contact.role_in_case}</Table.Cell>
            <Table.Cell>
              <a
                href="#"
                style={{ color: "#0000FF" }}
                onClick={(e) => {
                  e.preventDefault();
                  this.handleOpenConfirmModal(contact.contact_number as string);
                }}
              >
                {contact.contact_number}
              </a>
            </Table.Cell>
          </Table.Row>
        ))
    ) : (
      <Table.Row>
        <Table.Cell colSpan="3" style={{ textAlign: "center" }}>
          No contacts added yet.
        </Table.Cell>
      </Table.Row>
    )}
  </Table.Body>
</Table> */}
            <Modal
              open={apiResponseModalOpen}
              onClose={() => this.setState({ apiResponseModalOpen: false })}
              size="small"
            >
              {/* This is Modal of Item of key  */}
              <Modal.Header>Please Make Sure To Remember This Pin For Calling</Modal.Header>
              <Modal.Content>
                <p style={{ fontSize: "50px", textAlign: "center" }}>{apiResponseData}</p>  {/* Display API response data */}
              </Modal.Content>

              <Modal
                open={callItemModalOpen}
                onClose={() => this.setState({ callItemModalOpen: false })}
                size="small"
              >

                <Modal.Actions>
                  <Button negative onClick={() => this.setState({ callItemModalOpen: false })}>
                    Cancel
                  </Button>
                  <Button positive
                  // onClick={this.handleCallItemNumberSubmit}
                  >
                    Submit
                  </Button>
                </Modal.Actions>
              </Modal>
              <Modal.Actions>
                <Button
                  positive
                  onClick={() => {
                    this.setState({ apiResponseModalOpen: false }, () => {
                      this.setState({ callItemModalOpen: true });
                    });
                  }}
                >
                  OK
                </Button>
              </Modal.Actions>
            </Modal>
            <Modal
              open={this.state.confirmModalOpen}
              onClose={this.handleCloseConfirmModal}
              size="small"
            >

            </Modal>


          </Segment>
        )}


        <Accordion>
          <Accordion.Title active={this.state.active} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              primary
              disabled={
                caseState !== constants.caseStates.generated &&
                caseState !== constants.caseStates.open &&
                caseState !== constants.caseStates.reinvestigate
              }
              onClick={() => this.setState({ active: !this.state.active })}
            >
              <Icon name="dropdown" />
              Allocate Investigator
            </Button>
            {
              (this.state.userRole === constants.roleTitle.oh || this.state.userRole === constants.roleTitle.ceo || this.state.userRole === constants.roleTitle.md) && (
                <Button
                  style={{
                    backgroundColor: "#21ba45",
                    color: "white",
                    borderRadius: "8px",
                    fontSize: "14px",
                    fontWeight: "bold"
                  }}
                  onClick={async () => {
                    await this.fetchInvoiceDocument(this.props.model.getId());
                    this.handleOpenDocument();
                  }}
                >
                  Invoice Document
                </Button>
              )
            }

          </Accordion.Title>
          <Accordion.Content active={this.state.active}>
            <Segment loading={this.state.loading}>
              <Grid columns={2}>
                <Divider vertical>
                  <Icon name="arrow alternate circle right" />
                </Divider>
                <Grid.Row>
                  {/* investigator selection */}
                  <Grid.Column>
                    <Header as="h4">
                      Step 1
                      <Header.Subheader>
                        Select state and district in which you want to have an investigator,
                        you will see available investigator on that location below.
                        Selecting an investigator on the basis of state and district is optional if you want you can directly choose an investigator.
                      </Header.Subheader>
                    </Header>
                    <Form>
                      <Form.Dropdown
                        search
                        selection
                        label="Select State"
                        loading={this.state.loadingStates}
                        value={this.state.selected_state}
                        onChange={(_, { value, }) => {
                          this.setState({ selected_state: value as string })
                          this.getDistrict(value as string)
                        }}
                        options={this.state.stateOptions}
                        placeholder="Select State"
                      />
                      <Form.Dropdown
                        search
                        selection
                        label="Select District"
                        loading={this.state.loadingDistrict}
                        value={this.state.selected_district}
                        onChange={(_, { value, }) => {
                          this.setState({
                            selected_district: value as string,
                          })
                          this.getInvestigators(this.state.selected_state, value as string)
                        }
                        }
                        options={this.state.districtOptions}
                        placeholder="Select District"
                      />

                      <Form.Dropdown
                        search
                        selection
                        label="Select Invesitgator"
                        loading={this.state.loadingInvestigators}
                        value={this.state.alloc_selectedInvestigator}
                        onChange={(_, { value, }) =>
                          this.setState({
                            alloc_selectedInvestigator: value as string,
                          })

                        }
                        options={this.state.investigatorOptions}
                        placeholder="Select Investigator"
                      />

                      <Button
                        primary
                        icon
                        labelPosition="right"
                        onClick={() => {
                          this.setState({
                            alloc_selectedInvestigator: "",
                            selected_district: "",
                            selected_state: "",
                            districtOptions: [],

                          })
                          this.getInvestigators();
                        }
                        }
                        disabled={
                          this.state.alloc_selectedInvestigator.length === 0 &&
                          this.state.selected_district.length === 0 &&
                          this.state.selected_state.length === 0
                        }
                        style={{ marginBottom: "10px", width: "120px" }}
                      >
                        Clear
                        <Icon name="undo" />
                      </Button>
                      <Form.Dropdown
                        fluid
                        selection
                        multiple
                        label="Investigation Specifications"
                        placeholder="Select Multiple"
                        options={investigationSpecsOptions}
                        value={
                          this.state.alloc_selectedInvestigationSpecifications
                        }
                        onChange={(_, { value }) =>
                          this.setState({
                            alloc_selectedInvestigationSpecifications:
                              value as string[],
                          })
                        }
                      />
                      <label><strong> Services</strong></label>
                      <Grid>
                        <Grid.Row>
                          {this.state.servicesType.map((item, index) => <>
                            <Grid.Column width={8}>
                              <input
                                className="CaseTypeCheck"
                                key={index}
                                type="checkbox"
                                id={item}
                                value={item}
                                checked={this.state.services.includes(item)}
                                onChange={this.handleCheckboxChange}
                              />
                              <label htmlFor={item}>{item}</label>
                            </Grid.Column>
                          </>
                          )}
                        </Grid.Row>
                      </Grid>
                      <label><strong> Required Documents</strong></label>
                      <TagsInput
                        value={this.state.required_documents}
                        // onChange={(value) => this.setState({ required_documents: value })}
                        name="Required Documents"
                        placeHolder="PanCard"
                        disabled
                      />
                      <Form.Input
                        label="Other Specifications"
                        placeholder="e.g. Accidental, Health"
                        value={this.state.alloc_otherInvestigationSpecifications}
                        onChange={(_, { value }) =>
                          this.setState({
                            alloc_otherInvestigationSpecifications: value,
                          })
                        }
                      />
                      <Button
                        primary
                        icon
                        labelPosition="right"
                        onClick={this.onAdd}
                        disabled={
                          this.state.alloc_selectedInvestigator.length === 0 ||
                          this.state.services.length === 0 || this.state.required_documents.length === 0 ||
                          (this.state.alloc_selectedInvestigationSpecifications
                            .length === 0 &&
                            this.state.alloc_otherInvestigationSpecifications.trim()
                              .length === 0)
                        }
                      >
                        Add
                        <Icon name="arrow alternate circle right" />
                      </Button>
                    </Form>
                  </Grid.Column>
                  {/* investigator selection */}

                  {/* allocation  */}
                  <Grid.Column>
                    <Header as="h4">
                      Step 2
                      <Header.Subheader>
                        Click on the Allocate Button to start Investigation.
                        Investigators will receive an email with Case Detail.
                      </Header.Subheader>
                    </Header>

                    <Header as="h5">
                      Selected Investigators: {this.state.alloc_selection.length}
                    </Header>
                    <List>{selectionList}</List>
                    <Button
                      icon
                      positive
                      disabled={this.state.alloc_selection.length === 0}
                      labelPosition="right"
                      onClick={this.allocateInvesitgators}
                    >
                      Allocate Investigator
                      {this.state.alloc_selection.length > 1 ? "s" : ""}
                      <Icon name="check circle" />
                    </Button>
                  </Grid.Column>
                  {/* allocation  */}
                </Grid.Row>
              </Grid>
            </Segment>
          </Accordion.Content>
        </Accordion>
      </>
    );
  }
  private onAdd = () => {
    const id = this.state.alloc_selectedInvestigator;
    const investigator = this.findInvestigatorById(id);
    if (investigator === null) return;

    //remove investigator from options
    this.removeInvestigatorFromOptions(id);

    //get specs
    const specs = this.getSpecs();

    const serviceType = this.state.services
    const required_doc = this.state.required_documents

    // console.log(serviceType, required_doc, "qqqqqqqqqqqqqqqqqqqqqqqqq")
    //add investigator to selection list
    this.addInvestigatorInSelectionList(investigator, specs, serviceType, required_doc);
    //clear the current selection
    this.setState({
      alloc_selectedInvestigator: "",
      alloc_selectedInvestigationSpecifications: [],
      alloc_otherInvestigationSpecifications: "",
      selected_district: "",
      selected_state: ""

      // services: [],
      // required_documents: [],
    });
  };
  private removeInvestigatorFromOptions = (id: string) => {
    const options = this.state.investigatorOptions;
    const index = options.findIndex((it) => it.value === id);
    if (index === -1) return;
    options.splice(index, 1);
    this.setState({ investigatorOptions: [...options] });
  };
  private addInvestigatorInSelectionList = (
    investigator: User,
    specs: string[],
    serviceType: string[],
    required_doc: string[]
  ) => {
    const selection = this.state.alloc_selection;
    selection.push({ investigator, specs, serviceType, required_doc });
    this.setState({ alloc_selection: [...selection] });
  };
  private findInvestigatorById = (id: string) => {
    const index = this.state.investigators.findIndex((it) => it.getId() === id);
    if (index === -1) return null;
    return this.state.investigators[index];
  };
  private getSpecs = () => {
    const investigationSpecs = this.state.alloc_selectedInvestigationSpecifications;
    const services = this.state.services
    const required_doc = this.state.required_documents
    const otherSpecs = this.state.alloc_otherInvestigationSpecifications;

    let totalSpecs = [...investigationSpecs];
    if (this.state.alloc_otherInvestigationSpecifications.trim().length > 0) {
      totalSpecs = totalSpecs.concat(otherSpecs.split(","));
    }
    return totalSpecs;
  };
  private onRemove = (investigator: User) => {
    //remove investigator from selection list
    this.removeInvestigatorFromSelectionList(investigator);

    //add investigator back to list
    this.addInvestigatorInOptions(investigator);
  };
  private removeInvestigatorFromSelectionList = (investigator: User) => {
    const selection = this.state.alloc_selection;
    const index = selection.findIndex(
      (it) => it.investigator.getId() === investigator.getId()
    );
    if (index === -1) return;

    selection.splice(index, 1);
    this.setState({ alloc_selection: [...selection] });
  };
  private addInvestigatorInOptions = (investigator: User) => {
    const options = this.state.investigatorOptions;
    options.push(this.investigatorToDropDownOption(investigator));
    this.setState({ investigatorOptions: [...options] });
  };
  renderInvestigatorDropdownLabel = (model: User) => {
    const districts = User.getDistricts(model.getPrimaryLocations()).join();
    let colorCoding = "";
    let textColor = "";
    let fontSize = "";
    // #21ba45 rgb(50, 170, 90)
    if (model.getLevel()) {
      console.log(model.getLevel(), "Level");
      colorCoding = model.getLevel() === "primary" ? "#21ba45" :
        model.getLevel() === "secondary" ? "rgb(255, 232, 0)" :
          model.getLevel() === "tertiary" ? "rgb(215, 8, 0)" : "";
      if (colorCoding === "#21ba45" || colorCoding === "rgb(215, 8, 0)") {
        textColor = "#FFFFFF";
      }
      fontSize = colorCoding === "#21ba45" ? "16px" :
        colorCoding === "rgb(255, 232, 0)" ? "16px" :
          colorCoding === "rgb(215, 8, 0)" ? "16px" : fontSize;
    }
    return (
      <div style={{ display: "flex", backgroundColor: `${colorCoding}`, color: textColor, gap: "10px", padding: "8px 0px 8px 10px" }}>
        <Image src={model.getProfilePicURL()} avatar />
        <div style={{ margin: "0 4px" }}>
          <div style={{ fontSize: fontSize }}>{model.getName()}</div>
          <div style={{ fontSize: 12 }}>{districts}</div>
        </div>
      </div>
    );
  };
  private investigatorToDropDownOption = (investigator: User) => {
    // const style = investigator.style || "";
    return {
      key: investigator.getId(),
      value: investigator.getId(),
      text: investigator.getName(),
      content: this.renderInvestigatorDropdownLabel(investigator),
    };
  };

  private renderStateDropDownLabel = (model: States) => {
    return (
      <div style={{ display: "flex", }}>
        <div style={{ margin: "0 4px" }}>
          <div>{model.getName()}</div>
        </div>
      </div>
    );
  }

  private stateToDropDownOption = (state: States) => {
    return {
      key: state.getId(),
      value: state.getName(),
      text: state.getName(),
      content: this.renderStateDropDownLabel(state)
    }
  }

  private renderDistrictDropDownLabel = (model: string) => {
    return (
      <div style={{ display: "flex", }}>
        <div style={{ margin: "0 4px" }}>
          <div>{model}</div>
        </div>
      </div>
    );
  }

  private districtToDropDownOption = (district: string) => {
    return {
      key: district,
      value: district,
      text: district,
      content: this.renderDistrictDropDownLabel(district)
    }
  }
}
export default (props: any) => {
  return (
    <RequirePermission permission={permissions.Case.allocate}>
      <ActionAllocation {...props} />
    </RequirePermission>
  );
};
